import {
  Avatar,
  Card,
  Col,
  Divider,
  Empty,
  List,
  Row,
  Space,
  Typography,
} from "antd";
import { useAppSelector } from "../../store/hooks";
import { leaveSelectors } from "../../store/leaves";
import moment from "moment";
import { getRandomColor } from "../../utils/getRandomColor";
import noData from '../../assets/icons/no_data.svg';
import { useState } from "react";

const WhosOnLeaveCard = () => {
  const leaveList = useAppSelector(leaveSelectors.onLeave);
  
  const wfhLeaveRequest=leaveList.filter((leave)=>{
   return leave.req_type_id===7;
  })
  const leaveRequest=leaveList.filter((leave)=>{
   return leave.req_type_id!==7;
  })
  
  const [leave, setLeave] = useState(true);

  return (
    <Card
      title="Who's On Leave"
      bodyStyle={{ paddingTop: 12 }}
      style={{ minHeight: "360px", maxHeight: "360px" }}
    >
      <Row justify={"space-between"} align={"middle"} gutter={[0, 20]}>
        <Col style={{cursor:'pointer'}} onClick={()=>setLeave(true)}  >
          On Leave: <span className={"text-danger"} >{leaveRequest.length}</span>
        </Col>
        <Col style={{cursor:'pointer'}} onClick={()=>setLeave(false)} >
          WFH: <span className={"text-danger"}>{wfhLeaveRequest.length}</span>
        </Col>
        <Col span={24}>
          {(leave ? leaveRequest.length > 0 : wfhLeaveRequest.length > 0) ? (
            <List
              size="small"
              itemLayout="horizontal"
              dataSource={leave ? leaveRequest : wfhLeaveRequest}
              className={"user-leave-list"}
              renderItem={(
                {
                  request_type,
                  user,
                },
                index
              ) => (
                <List.Item key={`leave-${index}`} className={"ps-0"} style={{minWidth:"325px"}}>
                  <List.Item.Meta
                    avatar={
                      <Avatar style={{ backgroundColor: getRandomColor() }}>
                        {user.name.charAt(0)}
                      </Avatar>
                    }
                    title={user.name}
                    description={
                      <Space split={<Divider type={"vertical"} />}>
                        <Typography.Text type={"secondary"}>
                          {moment().format("DD MMM")}
                        </Typography.Text>
                        <Typography.Text type={"secondary"}>
                          {request_type.type} {leaveRequest[index]?.is_half_leave ? `(Half Day)` : null}
                        </Typography.Text>
                      </Space>
                    }
                  />
                </List.Item>
              )}
            />
          ) : (
            <Empty image={noData} description={"No one is on leave. Hurray!!"} />
          )}
        </Col>
      </Row>
    </Card>
  );
};

export default WhosOnLeaveCard;

import { createAction } from "@reduxjs/toolkit";
import { HistoryResponse,GetHistoryPayload, AttendanceReportPayload, UploadLogInterface } from "../../services/api/history/types";

// >>>>>>> Leave ActionTypes <<<<<<<<<
export const actionTypes = {
  GetHistory: "[History] GET History Details",
  GetHistoryDetailsSuccess: "[History] Get History Details Success",
  GetHistoryDetailsFailure: "[History] Get History Details Failure",

  AttendanceReport: "[History] Attendance Report",
  AttendanceReportSuccess: "[History] Attendance Report Success",
  AttendanceReportFailure: "[History] Attendance Report Failure",

  UploadLog: "[History] Upload Log",
  UploadLogSuccess: "[History] Upload Log Success",
  UploadLogFailure: "[History] Upload Log Failure",
};

// >>>>>>> Action Payload Interfaces <<<<<<<<<

// >>>>>>> Leave Actions <<<<<<<<<
export const actions = {
    getHistory: createAction<GetHistoryPayload>(actionTypes.GetHistory),
    getHistoryDetailsSuccess: createAction<HistoryResponse>(actionTypes.GetHistoryDetailsSuccess),
    getHistoryDetailsFailure: createAction<HistoryResponse>(actionTypes.GetHistoryDetailsFailure),

    attendanceReport: createAction<AttendanceReportPayload>(actionTypes.AttendanceReport),
    attendanceReportSuccess: createAction<HistoryResponse>(actionTypes.AttendanceReportSuccess),
    attendanceReportFailure: createAction<HistoryResponse>(actionTypes.AttendanceReportFailure),

    uploadLog: createAction<UploadLogInterface>(actionTypes.UploadLog),
    uploadLogSuccess: createAction<HistoryResponse>(actionTypes.UploadLogSuccess),
    uploadLogFailure: createAction<HistoryResponse>(actionTypes.UploadLogFailure),
};

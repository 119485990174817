import React, {useEffect} from "react";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {Button, Col, Row, Typography} from "antd";
import {HourglassOutlined} from "@ant-design/icons";
import LeaveCard from "../UI/LeaveCard";
import AnnouncementCard from "./AnnouncementCard";
import WhosOnLeaveCard from "./WhosOnLeaveCard";
import CelebrationsCard from "./CelebrationsCard";
import PieChart from "./Piechart";
import CreateLeaveRequestForm from "./Leaves/LeaveModel";
import './style.less';
import {leaveActions, leaveSelectors} from "../../store/leaves";
import { authSelectors } from "../../store/auth";
import YourLeave from "./YourLeave";
import { Announcementactions } from "../../store/announcements";
import { UserActions } from "../../store/users";


const Dashboard = () => {
  const leaveDetails = useAppSelector(leaveSelectors.getLeaveDetails);
  const getUser = useAppSelector(authSelectors.getUser);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(UserActions.getCurrentUserDetails());
    dispatch(leaveActions.getLeaveDetails({}));
    dispatch(leaveActions.getLeaverequests({}));
    dispatch(Announcementactions.getAnnouncementTypes({}));
    dispatch(leaveActions.getYourLeaves({}));
    dispatch(leaveActions.whoIsOnLeave({}));
    dispatch(Announcementactions.getAnnouncements({}));
    dispatch(UserActions.getUserStatistics({}));
  }, [dispatch]);
  return (
    <>
      <CreateLeaveRequestForm />

      <Row align={"middle"}>
        <Col flex={"auto"}>
          <Typography.Title level={4}>
            Welcome back, {getUser?.name} &#128075;
          </Typography.Title>
        </Col>
        <Col>
          <Button
            type={"primary"}
            icon={<HourglassOutlined />}
            onClick={() => 
            dispatch(leaveActions.leaveModelVisible({visible:true}))
            }
          >
            Make Request
          </Button>
        </Col>
      </Row>
      <Row className={"mt-3"} gutter={[0, 16]}>
        <Col span={24}>
          <div className={"leave-details-wrapper"}>
            {leaveDetails?.map((leave) => (
              <LeaveCard {...leave} key={`leave_${leave.id}`} />
            ))}
          </div>
        </Col>
        <Col span={24}>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={16} lg={16} xl={16} xxl={16}>
              <div className={"d-flex flex-column h-100"}>
                {/* <div className={"mb-3"}>
                  <AnnouncementCard />
                </div> */}
                <div
                  className={"flex-grow-1"}
                  style={{ background: "white", overflow: "auto" }}
                >
                  <PieChart />
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
              <Row gutter={[0, 16]}>
                <Col span={24}>
                  <WhosOnLeaveCard />
                </Col>
                <Col span={24}>
                  <CelebrationsCard />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        
          <Col span={24}>
            <YourLeave />
          </Col>
        
      </Row>
    </>
  );
};

export default Dashboard;

import {call, getContext, put, takeLeading} from "redux-saga/effects";
import {actionTypes} from "./actions";
import {PayloadAction} from "@reduxjs/toolkit";
import {DeleteRequestPayload, DownloadLeaveReportPayload, GetLeaveDetailsPayload, LeaveActionPayload, LeaveReportPayload, LeaveRequestPayload, UpdateLeaveRequestPayload, uploadDocsPayload} from "../../services/api/leaves/types";
import {LMSApi} from "../../services/api";
import { leaveActions } from './actions';
import { notification } from "antd";
import { LeaveState } from "./reducer";

const {
  getLeaveDetailsSuccess,
  getLeaveDetailsFailure,
  getLeaverequestsSuccess,
  getLeaverequestsFailure,
  getYourLeavesSuccess,
  getYourLeavesFailure,
  getLeaveTypesSuccess,
  getLeaveTypesFailure,
  makeLeaveRequestSuccess,
  makeLeaveRequestFailure,
  leaveActionSuccess,
  leaveActionFailure,
  leaveReportFailure,
  leaveReportSuccess,
  whoIsOnLeaveFailure,
  whoIsOnLeaveSuccess,
  deleteLeaveRequestSuccess,
  deleteLeaveRequestFailure,
  updateLeaveRequestSuccess,
  updateLeaveRequestFailure,
  downloadLeaveReportFailure,
  downloadLeaveReportSuccess,

  uploadDocFailure,
  uploadDocSuccess

} = leaveActions;

function* getLeaveDetailsFlow(action: PayloadAction<GetLeaveDetailsPayload>) {
  const api: LMSApi = yield getContext('api');
  const { leaveDetails, error } = yield call(api.leave.getLeaveDetails, action.payload);

  if (error) {
    yield put(getLeaveDetailsFailure({ leaveDetails: null, error }));
  } else {
    yield put(getLeaveDetailsSuccess({ leaveDetails, error: null }))
  }

}
function* getLeaveRequestFlow(action: PayloadAction<GetLeaveDetailsPayload>) {
  const api: LMSApi = yield getContext('api');
  const { leave, error } = yield call(api.leave.getLeaveRequests, action.payload);

  if (error) {
    yield put(getLeaverequestsFailure({ leave: null, error }));
  } else {
    yield put(getLeaverequestsSuccess({ leave, error: null }))
  }

}
function* getYourLeavesFlow(action: PayloadAction<GetLeaveDetailsPayload>) {
  const api: LMSApi = yield getContext('api');
  const { yourLeave, error } = yield call(api.leave.getYourLeaves, action.payload);

  if (error) {
    yield put(getYourLeavesFailure({ yourLeave: null, error }));
  } else {
    yield put(getYourLeavesSuccess({ yourLeave, error: null }))
  }

}

function* getLeaveTypesFlow(action: PayloadAction<GetLeaveDetailsPayload>) {
  const api: LMSApi = yield getContext('api');
  const { leavesTypes, error } = yield call(api.leave.getLeaveTypes, action.payload);

  if (error) {
    yield put(getLeaveTypesFailure({ leavesTypes: null, error }));
  } else {
    yield put(getLeaveTypesSuccess({ leavesTypes, error: null }))
  }

}

function* makeLeaveRequestFlow(action: PayloadAction<LeaveRequestPayload>) {
  const api: LMSApi = yield getContext('api');
  const { leaveRequest, error } = yield call(api.leave.makeLeaveRequest, action.payload);

  if (error) {
    yield put(makeLeaveRequestFailure({ leaveRequest: null, error }));
  } else {
    yield put(makeLeaveRequestSuccess({ leaveRequest, error: null }))
  }
}
function* leaveActionFlow(action: PayloadAction<LeaveActionPayload>) {
  const api: LMSApi = yield getContext('api');
  const { leaveAction, error } = yield call(api.leave.leaveAction, action.payload);

  if (error) {
    yield put(leaveActionFailure({ leaveAction: null, error }));
  } else {
    yield put(leaveActionSuccess({ leaveAction, error: null }))
  }
}

function* leaveReportFlow(action: PayloadAction<LeaveReportPayload>) {
  const api: LMSApi = yield getContext('api');
  const { leaveReport, error } = yield call(api.leave.leaveReport, action.payload);

  if (error) {
    yield put(leaveReportFailure({ leaveReport: null, error }));
  } else {
    yield put(leaveReportSuccess({ leaveReport, error: null }))
  }
}

function* downloadLeaveReportFlow(action: PayloadAction<DownloadLeaveReportPayload>) {
  const api: LMSApi = yield getContext('api');
  const { leaveReport, error } = yield call(api.leave.downloadLeaveReport, action.payload);

  if (error) {
    yield put(downloadLeaveReportFailure({ leaveReport: null, error }));
  } else {
    yield put(downloadLeaveReportSuccess({ leaveReport, error: null }))
  }
}

function* WhoIsOnLeaveFlow(action: PayloadAction<GetLeaveDetailsPayload>) {
  const api: LMSApi = yield getContext('api');
  const { onLeave, error } = yield call(api.leave.whoIsOnLeave, action.payload);

  if (error) {
    yield put(whoIsOnLeaveFailure({ onLeave: null, error }));
  } else {
    yield put(whoIsOnLeaveSuccess({ onLeave, error: null }))
  }
}

function* deleteLeaveRequestFlow(action: PayloadAction<DeleteRequestPayload>) {
  const api: LMSApi = yield getContext('api');
  const { deleteRequest, error } = yield call(api.leave.deleteRequest, action.payload);

  if (error) {
    yield put(deleteLeaveRequestFailure({ deleteRequest: null, error }));
  } else {
    yield put(deleteLeaveRequestSuccess({ deleteRequest, error: null }))
  }
}
function* updateLeaveRequestFlow(action: PayloadAction<UpdateLeaveRequestPayload>) {
  const api: LMSApi = yield getContext('api');
  const { updateRequest, error } = yield call(api.leave.updateLeaveRequest, action.payload);

  if (error) {
    yield put(updateLeaveRequestFailure({ updateRequest: null, error }));
  } else {
    yield put(updateLeaveRequestSuccess({ updateRequest, error: null }))
  }
}

function* uploadDocSaga(action: PayloadAction<uploadDocsPayload>) {
  const api: LMSApi = yield getContext('api');
  const { uploaddoc, error } = yield call(api.leave.uploadDocs, action.payload);

  if (error) {
    yield put(uploadDocFailure({ uploaddoc: null, error }));
  } else {
    yield put(uploadDocSuccess({ uploaddoc, error: null }))
  }
}



export default function* leaveSaga() {

  yield takeLeading(actionTypes.GetLeaveDetails, getLeaveDetailsFlow);
  yield takeLeading(actionTypes.GetLeaveRequests, getLeaveRequestFlow);
  yield takeLeading(actionTypes.GetYourLeaves, getYourLeavesFlow);
  yield takeLeading(actionTypes.GetLeaveTypes, getLeaveTypesFlow);
  yield takeLeading(actionTypes.MakeLeaveRequuest, makeLeaveRequestFlow);
  yield takeLeading(actionTypes.LeaveAction, leaveActionFlow);
  yield takeLeading(actionTypes.LeaveReport, leaveReportFlow);
  yield takeLeading(actionTypes.GetWhoIsOnLeave, WhoIsOnLeaveFlow);
  yield takeLeading(actionTypes.DeleteLeaveRequuest, deleteLeaveRequestFlow);
  yield takeLeading(actionTypes.UpdateLeaveRequest, updateLeaveRequestFlow);
  yield takeLeading(actionTypes.DownloadLeaveReport, downloadLeaveReportFlow);
  yield takeLeading(actionTypes.LeaveActionSuccess, function* (action: PayloadAction<LeaveState>) {
    yield notification.success({message: action.payload.leaveAction || "Something went wrong"})
  });
  yield takeLeading(actionTypes.LeaveActionFailure, function* (action: PayloadAction<LeaveState>) {
    yield notification.error({message: action.payload.error?.message || "Something went wrong"})
  }); 

  yield takeLeading(actionTypes.MakeLeaveRequuestSuccess, function* (action: PayloadAction<LeaveState>) {
    yield notification.success({message: "Request applied successfully" || "Something went wrong"})
  });
  yield takeLeading(actionTypes.MakeLeaveRequuestFailure, function* (action: PayloadAction<LeaveState>) {
    
    yield notification.error({message: action.payload.error?.message || "Something went wrong"})
  });

  yield takeLeading(actionTypes.UpdateLeaveRequestSuccess, function* (action: PayloadAction<LeaveState>) {
    yield notification.success({message: "Your request updated successfully" || "Something went wrong"})
  });
  yield takeLeading(actionTypes.UpdateLeaveRequestFailure, function* (action: PayloadAction<LeaveState>) {
    
    yield notification.error({message: action.payload.error?.message || "Something went wrong"})
  });
  yield takeLeading(actionTypes.DeleteLeaveRequuestSuccess, function* (action: PayloadAction<LeaveState>) {
    yield notification.success({message: "Your request deleted successfully" || "Something went wrong"})
  });
  yield takeLeading(actionTypes.DeleteLeaveRequuestFailure, function* (action: PayloadAction<LeaveState>) {
    
    yield notification.error({message: action.payload.error?.message || "Something went wrong"})
  });
  yield takeLeading(actionTypes.DownloadLeaveReportFailure, function* (action: PayloadAction<LeaveState>) {
    
    yield notification.error({message: action.payload.error?.message || "Something went wrong"})
  });
 
  // upload doc 
  yield takeLeading(actionTypes.UploadDoc, uploadDocSaga);
  yield takeLeading(actionTypes.UploadDocSuccess, function* (action: PayloadAction<LeaveState>) {
    
    yield notification.success({message: "Document uploaded successfully"})
  });
  yield takeLeading(actionTypes.UploadDocFailure, function* (action: PayloadAction<LeaveState>) {
    
    yield notification.error({message: action.payload.error?.message || "Document size less than 1MB and it must be in jpg, png & pdf format"})
  });
  
}
import { createAction } from "@reduxjs/toolkit";
import {DeleteRequestPayload, DownloadLeaveReportPayload, GetLeaveDetailsPayload, LeaveActionPayload, LeaveModelVisiblePayload, LeaveReportPayload, LeaveRequestPayload, LeaveResponse, SelectLeaveRequestIdPayload, UpdateLeaveIdPayload, UpdateLeaveRequestPayload, uploadDocsPayload} from "../../services/api/leaves/types";

// >>>>>>> Leave ActionTypes <<<<<<<<<
export const actionTypes = {
  GetLeaveDetails: "[Leave] Get Leave Details",
  GetLeaveDetailsSuccess: "[Leave] Get Leave Details Success",
  GetLeaveDetailsFailure: "[Leave] Get Leave Details Failure",

  GetLeaveRequests: "[Leave] Get Leave Request",
  GetLeaveRequestsSucess: "[Leave] Get Leave Request Success",
  GetLeaveRequestsFailure: "[Leave] Get Leave Request Failure",

  GetYourLeaves: "[Leave] Get Your Leaves",
  GetYourLeavesSucess: "[Leave] Get Your Leaves Success",
  GetYourLeavesFailure: "[Leave] Get Your Leaves Failure",

  GetLeaveTypes: "[Leave] Get Leave Types",
  GetLeaveTypesSucess: "[Leave] Get Leave Types Success",
  GetLeaveTypesFailure: "[Leave] Get Leave Types Failure",
  
  MakeLeaveRequuest: "[Leave] Make Leave Requuest",
  MakeLeaveRequuestSuccess: "[Leave] Make Leave Requuest Success",
  MakeLeaveRequuestFailure: "[Leave] Make Leave Requuest Failure",

  LeaveAction: "[Leave] Leave Action",
  LeaveActionSuccess: "[Leave] Leave Action Success",
  LeaveActionFailure: "[Leave] Leave Action Failure",

  LeaveReport: "[Leave] Leave Report",
  LeaveReportSuccess: "[Leave] Leave Report Success",
  LeaveReportFailure: "[Leave] Leave Report Failure",

  DownloadLeaveReport: "[Leave] Download Leave Report",
  DownloadLeaveReportSuccess: "[Leave] Download Leave Report Success",
  DownloadLeaveReportFailure: "[Leave] Download Leave Report Failure",

  GetWhoIsOnLeave: "[Leave] Get Who Is On Leave",
  GetWhoIsOnLeaveSuccess: "[Leave] Get Who Is On Leave Success",
  GetWhoIsOnLeaveFailure: "[Leave] Get Who Is On Leave Failure",

  DeleteLeaveRequuest: "[Leave] Delete Leave Requuest",
  DeleteLeaveRequuestSuccess: "[Leave] Delete Leave Requuest Success",
  DeleteLeaveRequuestFailure: "[Leave] Delete Leave Requuest Failure",

  UpdateLeaveRequest: "[Leave] Update Leave Requuest",
  UpdateLeaveRequestSuccess: "[Leave] Update Leave Requuest Success",
  UpdateLeaveRequestFailure: "[Leave] Update Leave Requuest Failure",

  SelectedLeaveId: "[Leave] Select Leave Id",

  SelectedLeaveRequestId: "[Leave] Select Leave Request Id",

  LeaveModelVisible : "[Leave] Leave Model Visible",
  UpdateLeaveModelVisible : "[Leave] Update Leave Model Visible",

  UploadDoc : "[Upload Doc] Upload Doc",
  UploadDocSuccess : "[Upload Doc] Upload Doc Success",
  UploadDocFailure : "[Upload Doc] Upload Doc Failed",
};

// >>>>>>> Action Payload Interfaces <<<<<<<<<

// >>>>>>> Leave Actions <<<<<<<<<
export const leaveActions = {
  getLeaveDetails: createAction<GetLeaveDetailsPayload>(actionTypes.GetLeaveDetails),
  getLeaveDetailsSuccess: createAction<LeaveResponse>(actionTypes.GetLeaveDetailsSuccess),
  getLeaveDetailsFailure: createAction<LeaveResponse>(actionTypes.GetLeaveDetailsFailure),

  getLeaverequests: createAction<GetLeaveDetailsPayload>(actionTypes.GetLeaveRequests),
  getLeaverequestsSuccess: createAction<LeaveResponse>(actionTypes.GetLeaveRequestsSucess),
  getLeaverequestsFailure: createAction<LeaveResponse>(actionTypes.GetLeaveRequestsFailure),

  getYourLeaves: createAction<GetLeaveDetailsPayload>(actionTypes.GetYourLeaves),
  getYourLeavesSuccess: createAction<LeaveResponse>(actionTypes.GetYourLeavesSucess),
  getYourLeavesFailure: createAction<LeaveResponse>(actionTypes.GetYourLeavesFailure),

  getLeaveTypes: createAction<GetLeaveDetailsPayload>(actionTypes.GetLeaveTypes),
  getLeaveTypesSuccess: createAction<LeaveResponse>(actionTypes.GetLeaveTypesSucess),
  getLeaveTypesFailure: createAction<LeaveResponse>(actionTypes.GetLeaveTypesFailure),

  makeLeaveRequest: createAction<LeaveRequestPayload>(actionTypes.MakeLeaveRequuest),
  makeLeaveRequestSuccess: createAction<LeaveResponse>(actionTypes.MakeLeaveRequuestSuccess),
  makeLeaveRequestFailure: createAction<LeaveResponse>(actionTypes.MakeLeaveRequuestFailure),

  leaveAction: createAction<LeaveActionPayload>(actionTypes.LeaveAction),
  leaveActionSuccess: createAction<LeaveResponse>(actionTypes.LeaveActionSuccess),
  leaveActionFailure: createAction<LeaveResponse>(actionTypes.LeaveActionFailure),

  leaveReport: createAction<LeaveReportPayload>(actionTypes.LeaveReport),
  leaveReportSuccess: createAction<LeaveResponse>(actionTypes.LeaveReportSuccess),
  leaveReportFailure: createAction<LeaveResponse>(actionTypes.LeaveReportFailure),

  downloadLeaveReport: createAction<DownloadLeaveReportPayload>(actionTypes.DownloadLeaveReport),
  downloadLeaveReportSuccess: createAction<LeaveResponse>(actionTypes.DownloadLeaveReportSuccess),
  downloadLeaveReportFailure: createAction<LeaveResponse>(actionTypes.DownloadLeaveReportFailure),

  whoIsOnLeave: createAction<GetLeaveDetailsPayload>(actionTypes.GetWhoIsOnLeave),
  whoIsOnLeaveSuccess: createAction<LeaveResponse>(actionTypes.GetWhoIsOnLeaveSuccess),
  whoIsOnLeaveFailure: createAction<LeaveResponse>(actionTypes.GetWhoIsOnLeaveFailure),

  deleteLeaveRequest: createAction<DeleteRequestPayload>(actionTypes.DeleteLeaveRequuest),
  deleteLeaveRequestSuccess: createAction<LeaveResponse>(actionTypes.DeleteLeaveRequuestSuccess),
  deleteLeaveRequestFailure: createAction<LeaveResponse>(actionTypes.DeleteLeaveRequuestFailure),

  updateLeaveRequest: createAction<UpdateLeaveRequestPayload>(actionTypes.UpdateLeaveRequest),
  updateLeaveRequestSuccess: createAction<LeaveResponse>(actionTypes.UpdateLeaveRequestSuccess),
  updateLeaveRequestFailure: createAction<LeaveResponse>(actionTypes.UpdateLeaveRequestFailure),

  selectedLeaveId : createAction<UpdateLeaveIdPayload>(actionTypes.SelectedLeaveId),

  selectLeaveRequestId : createAction<SelectLeaveRequestIdPayload>(actionTypes.SelectedLeaveRequestId),

  leaveModelVisible : createAction<LeaveModelVisiblePayload>(actionTypes.LeaveModelVisible),
  updateLeaveModelVisible : createAction<LeaveModelVisiblePayload>(actionTypes.UpdateLeaveModelVisible),

  uploadDoc: createAction<uploadDocsPayload>(actionTypes.UploadDoc), 
  uploadDocSuccess: createAction<LeaveResponse>(actionTypes.UploadDocSuccess), 
  uploadDocFailure: createAction<LeaveResponse>(actionTypes.UploadDocFailure), 
};

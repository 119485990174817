import { lmsApiService } from "../../plugins/axios";
import {
  AttendanceReportPayload,
  GetHistoryPayload,
  HistoryApiInterface,
  HistoryResources,
  UploadLogInterface,
} from "./types";

const HISTORY_ENDPOINTS: HistoryResources = {
  // get attendance for perticular user only
  GET_ATTENDANCE: {
    URL: "/attendance-report",
    METHOD: "POST",
  },
  // get attendance for report
  ATTENDANCE_REPORT: {
    URL: "/attendance-report",
    METHOD: "POST",
  },
  // Upload attendance
  UPLOAD_LOG: {
    URL: "/uploadLog",
    METHOD: "POST",
  },
};

const historyAPI: HistoryApiInterface = {
  getHistory: async (payload: GetHistoryPayload) => {
    const { response, error } = await lmsApiService({
      resource: HISTORY_ENDPOINTS.GET_ATTENDANCE,
      options: {
        data: {
          start: payload.startDate,
          end: payload.endDate,
        },
      },
    });
    if (error) {
      return { error, historyDetails: null };
    }
    return {
      historyDetails: response,
      error: null,
    };
  },
  attendanceReport: async (payload: AttendanceReportPayload) => {
    const { response, error } = await lmsApiService({
      resource: HISTORY_ENDPOINTS.ATTENDANCE_REPORT,
      options: {
        data: {
          user_id: payload.user_id,
          start: payload.start,
          end: payload.end,
        },
      },
    });
    if (error) {
      return { error, attendaceReport: null };
    }
    return {
      attendaceReport: response,
      error: null,
    };
  },
  uploadLogs: async (payload: UploadLogInterface) => {
    const {  error } = await lmsApiService({
      resource: HISTORY_ENDPOINTS.UPLOAD_LOG,
      options: {
        data: payload,
      },
    });
    if (error) {
      return { error, attendaceReport: null };
    }
    return {
      attendaceReport: null,
      error: null,
    };
  },
};

export default historyAPI;

export { HISTORY_ENDPOINTS };

import { PayloadAction } from "@reduxjs/toolkit";
import { LMSApi } from "../../services/api";
import {
  LoginPayload,
  ChangePasswordPayload,
  RegisterPayload,
  ForgotPasswordPayload,
} from "../../services/api/auth/types";
import {
  put,
  getContext,
  call,
  takeLeading,
} from "redux-saga/effects";
import { actions, actionTypes } from "./actions";
import {
  AuthState,
  resetAuthState,
  setAuthToken,
} from "./reducer";
import {  notification } from "antd";

// >>>>>>>>> Auth Actions <<<<<<<<
const {
  loginSuccess,
  loginFailure,
  logoutSuccess,
  logoutFailure,
  renewAccessTokenFailure,
  forgotPasswordFailure,
  forgotPasswordSuccess,
  changePasswordSuccess,
  changePasswordFailure,
  registerFailure,
  registerSuccess,
  
} = actions;

// >>>>>>>> Auth Flows <<<<<<<<<
// Login
function* loginFlow(action: PayloadAction<LoginPayload>) {
  // Access the api service of auth module
  // Directly accessible from getContext, no importing required
  const api: LMSApi = yield getContext("api");
  // Call the api service and wait for authState to be returned
  const authState: AuthState = yield call(api.auth.login, action.payload);

  // If there is an error, dispatch loginFailure
  if (authState.error) {
    yield put(loginFailure(authState));
  }

  // If there is no error, dispatch loginSuccess
  if (authState.authToken) {
    yield put(loginSuccess(authState));
  }
}
function* registerFlow(action: PayloadAction<RegisterPayload>) {
  // Access the api service of auth module
  // Directly accessible from getContext, no importing required
  const api: LMSApi = yield getContext("api");
  // Call the api service and wait for authState to be returned
  const { error } = yield  call(api.auth.register, action.payload);
  if (error) {
    yield put(registerFailure({ error }));
  } else {
    yield put(registerSuccess({}))
  }
  
}

function* logoutFlow() {
  const api: LMSApi = yield getContext("api");
  const authState: AuthState = yield call(api.auth.logout);

  if (authState.error) {
    yield put(logoutFailure(authState));
  }

  // Dispacth logout success
  yield put(logoutSuccess(authState));
}


function* renewAccessTokenFlow(action: PayloadAction<string>) {
  const api: LMSApi = yield getContext("api");
  const { authToken, error }: AuthState = yield call(
    api.auth.refreshToken,
    action.payload
  );

  if (authToken) {
    // Update Local Storage for backwards compatibility
    localStorage.setItem("authToken", authToken);

    // Dispatch refresh token success
    yield put(setAuthToken(authToken));
  } else if (error) {
    yield put(
      renewAccessTokenFailure({
        error,
      })
    );
  }
}

function* forgotPasswordFlow(action:PayloadAction<ForgotPasswordPayload>) {
  const api: LMSApi = yield getContext("api");
  const authState: AuthState = yield call(api.auth.forgotPassword,action.payload);

  if (authState.error) {
    yield put(forgotPasswordFailure(authState));
  }
  else{
    yield put(forgotPasswordSuccess(authState));
  }
  // Dispacth logout success
  
}

function* changePasswordFlow(action: PayloadAction<ChangePasswordPayload>) {
  const api: LMSApi = yield getContext("api");
  const authState: AuthState = yield call(
    api.auth.changePassword,
    action.payload
  );

  if (authState.error) {
    yield put(changePasswordFailure(authState));
  }
  else {
    yield put(changePasswordSuccess(authState));
  }

  yield put(resetAuthState());
}

// >>>>>>>> Auth Sagas <<<<<<<<<
export default function* authSaga() {
  yield takeLeading(actionTypes.Login, loginFlow);
  yield takeLeading(actionTypes.LoginFailure, function* (action: PayloadAction<AuthState>) {
    yield notification.error({message: action.payload.error?.message || "Something went wrong"})
  });
  // yield takeLeading(actionTypes.LoginSuccess,notification.open(message:"Login Success") as string);
  // yield takeLeading(actionTypes.LoginFailure,notification.open(message:"Login Success") as string);

  yield takeLeading(actionTypes.Register, registerFlow);
  yield takeLeading(actionTypes.RegisterSuccess, function* (action: PayloadAction<AuthState>) {
    yield notification.success({message: "Registered Successfully" || "Something went wrong"})
  });
  yield takeLeading(actionTypes.RegisterFailure, function* (action: PayloadAction<AuthState>) {
    yield notification.error({message: action.payload.error?.message || "Something went wrong"})
  });
  yield takeLeading(actionTypes.Logout, logoutFlow);

  yield takeLeading(actionTypes.RenewAccessToken, renewAccessTokenFlow);

  yield takeLeading(actionTypes.ChangePassword, changePasswordFlow);
  
  yield takeLeading(actionTypes.ForgotPassword,forgotPasswordFlow);
  yield takeLeading(actionTypes.ForgotPasswordSuccess, function* (action: PayloadAction<AuthState>) {
    yield notification.success({message: "Mail Sent Successfully !" || "Something went wrong"})
  });
  yield takeLeading(actionTypes.ForgotPasswordFailure, function* (action: PayloadAction<AuthState>) {
    yield notification.error({message: "Please Emter Valid Mail !" || "Something went wrong"})
  });
  yield takeLeading(actionTypes.ChangePasswordSuccess, function* (action: PayloadAction<AuthState>) {
    yield notification.success({message: "Password Changed Successfully !" || "Something went wrong"})
  });
  yield takeLeading(actionTypes.ChangePasswordFailure, function* (action: PayloadAction<AuthState>) {
    yield notification.error({message: action.payload.error?.message || "Something went wrong"})
  });

}





import React from 'react';
import './App.less';
import {BrowserRouter, Navigate, Route, Routes,} from "react-router-dom";
import Login from "./components/Login";
import LayoutComponent from "./components/LayoutComponent";
import {useAppSelector} from "./store/hooks";
import {authSelectors} from "./store/auth";
import ChangePasswordForm from './components/Navbar/ChangePasswordForm';

const App = () => {

  const isAuthenticated = useAppSelector(authSelectors.getIsAuthenticated);

  return (
    <BrowserRouter>
      <Routes>
        {isAuthenticated ? (
          <>
          <Route path={'*'} element={<LayoutComponent />} />
          
          </>
        ) : (
          <>
            <Route path={'login'} element={<Login/>}/>
            <Route path={"change-password"} element={<ChangePasswordForm />} />
            <Route path={'*'} element={<Navigate to={'login'} replace/>}/>
          </>
        )}
      </Routes>
    </BrowserRouter>
  );
}

export default App;

import React from 'react';
import './style.less';
import {Col, Row, Typography,Image} from "antd";
import authBg from '../../assets/images/auth-bg.png';
import logo from '../../assets/logo.png';
import LoginForm from "./LoginForm";


const Login = () => {

  return (
    <div className={'login-wrapper'}>
      <Row className={'h-100'}>
        <Col span={12} xs={'none'} sm={'none'} className={'login-banner'}>
          <div className={'d-flex align-items-center flex-column h-100'}>
            <div className={'login-desc flex-grow-1'}>
              <Typography.Title style={{color:"#372C8B"}}>
                Leave Management
              </Typography.Title>
              <Typography.Paragraph>
                Vel praesent nullam inceptos non, ut lacinia at, mauris sollicitudin purus risus amet erat vitae dolor
                dictumst himenaeos aliquam.nullam inceptos non, ut lacinia at, mauris sollicitudin purus risus .
              </Typography.Paragraph>
            </div>
            <div>
              <Image src={authBg} preview={false}></Image>
            </div>
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} xl={12} lg={12} xxl={12} className={'login-form'}>
          <Row>
            <Col span={24} className={'text-center'}>
              <Image src={logo} preview={false} alt={'logo'}></Image>
            </Col>
            <Col span={24} className={'text-center mt-4'}>
              <Typography.Title level={3} style={{color:"#372C8B"}}>Login</Typography.Title>
            </Col>
            <Col span={24}>
              <LoginForm/>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )
}

export default Login;
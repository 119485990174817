import React from "react";
import { Menu, MenuProps } from "antd";
import { useNavigate,useLocation } from "react-router-dom";
import {
  ClockCircleFilled,
  DashboardFilled,
  FileTextFilled,
  UsergroupAddOutlined,
  AlignLeftOutlined,
} from "@ant-design/icons";
import { useAppSelector } from "../../store/hooks";
import { authSelectors } from "../../store/auth";


const Sidebar = () => {
  const getUser = useAppSelector(authSelectors.getUser);
  let items;
  getUser?.scope === "GEN"
    ? (items = [
        { label: "Dashboard", key: "dashboard", icon: <DashboardFilled /> }, // remember to pass the key prop
        { label: "Attendance", key: "attendance", icon: <ClockCircleFilled /> }, // which is required
      ])
    : (items = [
        { label: "Dashboard", key: "dashboard", icon: <DashboardFilled /> }, // remember to pass the key prop
        { label: "Attendance", key: "attendance", icon: <ClockCircleFilled /> }, // remember to pass the key prop
        {
          label: "Leave Request",
          key: "leave-request",
          icon: <AlignLeftOutlined />,
        }, // which is required
        { label: "Reports", key: "reports", icon: <FileTextFilled /> }, // which is required
        {
          label: "User Details",
          key: "user-details",
          icon: <UsergroupAddOutlined />,
        }, // which is required
      ]);

  const navigate = useNavigate();
      const location = useLocation();
  const [current, setCurrent] = React.useState(location.pathname.split("/")[1]);
  
  const onClick: MenuProps["onClick"] = (e) => {
    setCurrent(e.key);
    navigate(e.key);
  };

  return (
    <Menu
      mode="inline"
      items={items}
      selectedKeys={[current]}
      onClick={onClick}
    />
  );
};

export default Sidebar;

import React from "react";
import { Card} from "antd";
import CalenderForm from "./calender";


const History = () => {
  return (
    <Card title="Attendance" style={{overflow:"auto"}}>
      <CalenderForm/>
    </Card>
  );
};

export default History;

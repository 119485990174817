import { lmsApiService } from "../../plugins/axios";
import {
  DeleteRequestPayload,
  DownloadLeaveReportPayload,
  LeaveActionPayload,
  LeaveApiInterface,
  LeaveReportPayload,
  LeaveRequestPayload,
  LeaveResources,
  UpdateLeaveRequestPayload,
  uploadDocsPayload,
} from "./types";

const LEAVE_ENDPOINTS: LeaveResources = {
  // get leave details card on dashboard
  GET_LEAVE_DETAILS: {
    URL: "/leave-details",
    METHOD: "GET",
  },
  // all pending leave request on leave request section
  LEAVE_REQUEST_ALL: {
    URL: "/leave-request/all",
    METHOD: "GET",
  },
  // get your leaves on dashboard
  YOUR_LEAVES: {
    URL: "/leave-request",
    METHOD: "GET",
  },
  // get leaves types
  LEAVE_TYPES: {
    URL: "/leave-request-type",
    METHOD: "GET",
  },
  // Apply leave request
  MAKE_LEAVE_REQUEST: {
    URL: "/leave-request",
    METHOD: "POST",
  },
  // Update leave request
  UPDATE_LEAVE_REQUEST: {
    URL: "/leave-request/:id",
    METHOD: "PUT",
  },
  // Delete leave request
  DELETE_LEAVE_REQUEST: {
    URL: "/leave-request/:id",
    METHOD: "DELETE",
  },
  // Approve/decline leave request
  LEAVE_ACTION: {
    URL: "/leave-action",
    METHOD: "POST",
  },
  // Leave report 
  LEAVE_REPORT: {
    URL: "/leave-report",
    METHOD: "POST",
  },
  // fetch leave who is on leave today on dashboard
  WHO_IS_ON_LEAVE: {
    URL: "/whois-onleave",
    METHOD: "GET",
  },
  // Download leave request
  DOWNLOAD_LEAVE_REPORT: {
    URL: "leave-report/download",
    METHOD: "POST",
  },
  // upload docs for seek leave
  UPLOAD_DOC: {
    URL: "upload-doc",
    METHOD: "POST",
  },
};

const LeaveApi: LeaveApiInterface = {
  getLeaveDetails: async () => {
    const { response, error } = await lmsApiService({
      resource: LEAVE_ENDPOINTS.GET_LEAVE_DETAILS,
    });
    if (error) {
      return { error, leaveDetails: null };
    }

    return {
      leaveDetails: response.data,
      error: null,
    };
  },
  getLeaveRequests: async () => {
    const { response, error } = await lmsApiService({
      resource: LEAVE_ENDPOINTS.LEAVE_REQUEST_ALL,
    });
    if (error) {
      return { error, leave: null };
    }
    return {
      leave: response.data,
      error: null,
    };
  },
  whoIsOnLeave: async () => {
    const { response, error } = await lmsApiService({
      resource: LEAVE_ENDPOINTS.WHO_IS_ON_LEAVE,
    });
    if (error) {
      return { error, onLeave: null };
    }
    return {
      onLeave: response.data,
      error: null,
    };
  },
  getYourLeaves: async () => {
    const { response, error } = await lmsApiService({
      resource: LEAVE_ENDPOINTS.YOUR_LEAVES,
    });
    if (error) {
      return { error, yourLeave: null };
    }

    return {
      yourLeave: response.data,
      error: null,
    };
  },
  getLeaveTypes: async () => {
    const { response, error } = await lmsApiService({
      resource: LEAVE_ENDPOINTS.LEAVE_TYPES,
    });
    if (error) {
      return { error, leavesTypes: null };
    }
    return {
      leavesTypes: response,
      error: null,
    };
  },
  makeLeaveRequest: async (data: LeaveRequestPayload) => {
    const { response, error } = await lmsApiService({
      resource: LEAVE_ENDPOINTS.MAKE_LEAVE_REQUEST,
      options: {
        data,
      },
    });
    if (error) {
      return { error, data: null };
    }
    return { error: null, data: response.message };
  },
  leaveAction: async (data: LeaveActionPayload) => {
    const { response, error } = await lmsApiService({
      resource: LEAVE_ENDPOINTS.LEAVE_ACTION,
      options: {
        data,
      },
    });
    if (error) {
      return { error, leaveAction: null };
    }

    return { error: null, leaveAction: response.message };
  },

  leaveReport: async (data: LeaveReportPayload) => {
    const { response, error } = await lmsApiService({
      resource: LEAVE_ENDPOINTS.LEAVE_REPORT,
      options: {
        data,
      },
    });
    if (error) {
      return { error, leaveReport: null };
    }

    return { error: null, leaveReport: response.data };
  },
  downloadLeaveReport: async (data: DownloadLeaveReportPayload) => {
    const { response, error } = await lmsApiService({
      resource: LEAVE_ENDPOINTS.DOWNLOAD_LEAVE_REPORT,
      options: {
        data,
      },
    });
    if (error) {
      return { error, leaveReport: null };
    }

    return { error: null, leaveReport: response.data };
  },
  deleteRequest: async (data: DeleteRequestPayload) => {
    const { response, error } = await lmsApiService({
      resource: LEAVE_ENDPOINTS.DELETE_LEAVE_REQUEST,
      options: {
        pathVars: {
          id: data.id,
        },
      },
    });
    if (error) {
      return { error, deleteRequest: null };
    }
    return { error: null, deleteRequest: response.data };
  },
  updateLeaveRequest: async (data: UpdateLeaveRequestPayload) => {
    const { response, error } = await lmsApiService({
      resource: LEAVE_ENDPOINTS.UPDATE_LEAVE_REQUEST,
      options: {
        data,
        pathVars: {
          id: data.req_id,
        },
      },
    });

    if (error) {
      return { error, updateRequest: null };
    }
    return { error: null, updateRequest: response.data };
  },
  uploadDocs: async (payload: uploadDocsPayload) => {

    var formData = new FormData();
    console.log(payload?.file?.originFileObj);
    

    formData.append('leave_id',payload.leave_id.toString());
    formData.append('file',payload?.file?.originFileObj);
    
    const { error, response } = await lmsApiService({
      resource: LEAVE_ENDPOINTS.UPLOAD_DOC,
      headers: { 
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        'Content-Type':'multipart/form-data'
      },
      options: {
        data: formData
      },
    });
    if (error) {
      return { error, uploaddoc: null };
    }
    return { error: null, uploaddoc: response.message };
  },
};

export default LeaveApi;

export { LEAVE_ENDPOINTS };

import React, { useEffect, useState } from "react";
import "./style.less";
import { Avatar, Button, Col, Menu, Modal, Row } from "antd";
import {
  ExclamationCircleOutlined,
  InfoCircleOutlined,
  MenuOutlined,
} from "@ant-design/icons";
import "./style.less";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { authActions, authSelectors } from "../../store/auth";
import { useNavigate } from "react-router-dom";
import ForgotPassword from "../Login/ForgotPassword";
const { confirm } = Modal;

interface dialogInterface {
  setSidebarVisible: any;
}
const Navbar = ({ setSidebarVisible }: dialogInterface) => {
  const getUser = useAppSelector(authSelectors.getUser);
  const isAuthenticated = useAppSelector(authSelectors.getIsAuthenticated);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [modelVisible, setmodelVisible] = useState(false);


  const logoutModal = () => {
    confirm({
      title: "Are you sure want to logout ??",
      icon: <ExclamationCircleOutlined />,
      content: "Thank you for using F(x) leave app",
      onOk() {
        dispatch(authActions.logout());
      },
    });
  };
  useEffect(() => {
    if (isAuthenticated === false) {
      navigate("/login");
    }
  }, [isAuthenticated, navigate]);
  function info() {
    Modal.info({
      width: 800,
      title: "Leave Request Information",
      content: (
        <div className="user-info">
          <ul>
            <li>
              You can create, update and delete leave request. Available leaves
              for current year is also shown.
            </li>
            <li>
              You will get email when admin responses to your leave request.
            </li>
            <li>Rules to follow when making request.</li>
          </ul>
          <div style={{ padding: "10" }}>
            <div className="info-border">
              1. Available leaves in one year
              <br />
              {getUser?.is_training === false ?
                <div>
                  Casual Leave (5), Sick Leave (5) , Earned Leave (7) <br />
                  Adjustment Leave (4), Unpaid Leave (-), Alternate Shift (-), WFH (-)
                </div>
                :
                <div>
                  Casual Leave (6), Sick Leave (6), Adjustment Leave (12) <br />
                  Unpaid Leave (-), Alternate Shift (-), WFH (-)
                </div>
              }
            </div>
            <div className="info-border">
              2. Leave Request should be made 24 hours prior to leave date
            </div>
            {getUser?.is_training === false ? (
              <>
                <div className="info-border">
                  3. Only 5 users can have leave on the same day
                </div>
                <div className="info-border">
                  4. If leave request is denied and leave is still taken, then 2x amount will be deducted
                </div>
                <div className="info-border">
                  5. Only 1 Adjustment leave can be taken within month
                </div>
                <div className="info-border">
                  6. For Adjustment leave, if you are not able to fill the day you
                  selected then the leave will be considered as an Unpaid leave
                </div>
                <div className="info-border">
                  7. Adjustment leave should be adjusted within 30 days for boys and
                  within 40 days for girls
                </div>
                <div className="info-border">
                  8. For Emergency Leave : <br />
                  <ul>
                    <li>Apply for Leave</li>
                    <li>Tick the check box for Emergency Leave</li>
                  </ul>
                </div>
                <div className="info-border">
                  9. Emergency leave can be taken once a month and 7 times a Year
                </div>
                <div className="info-border">
                  10. Alternate shift can be taken 4 times in month
                </div>
                <div className="info-border">
                  11. For Casual and Earned leave only 4 can be taken per quarter
                </div>
              </>
            ) : (
              <>
                <div className="info-border">
                  3. If leave request is denied and leave is still taken, then 2x amount will be deducted
                </div>
                <div className="info-border">
                  4. Adjustment leave can be taken once in a month. For female employees, it should be adjusted within a 40-day time frame, and for male employees, it should be adjusted within 30 days. This adjustment must occur on a Saturday and in-office; remote adjustments are not allowed.
                </div>
                <div className="info-border">
                  5. For Adjustment leave, if you are not able to fill the day you
                  selected then the leave will be considered as an Unpaid leave
                </div>
                <div className="info-border">
                  6. Sick leave can be taken as needed but should be used only within the available limit.
                </div>
                <div className="info-border">
                  7. For Emergency Leave : <br />
                  <ul>
                    <li>Apply for Leave</li>
                    <li>Tick the check box for Emergency Leave</li>
                  </ul>
                </div>
                <div className="info-border">
                  8. Emergency leave can be taken once a month and 7 times a Year
                </div>
                <div className="info-border">
                  9. Alternate shift can be taken maximum 4 times in a month
                </div>
                <div className="info-border">
                  10. Work from home policy : <br />
                  <ul>
                    <li>
                      If an employee opts for WFH for a single day within a quarter, a deduction of 10% will be applied to their remuneration for that particular day.
                    </li>
                    <li>
                      If an employee choose WFH for a period exceeding one day but not exceeding one week, a deduction of 15% will be made for each WFH day. Furthermore, this deduction of 15% shall be retroactively applicable to all preceding days of WFH within the same quarter.
                    </li>
                    <li>
                      If an employee opt WFH for a duration surpassing one week within the same quarter, a deduction of 18% will be enforced for each WFH day. Similar to the preceding provision, this deduction of 18% shall be retrospectively applicable to all preceding days of WFH within the stipulated period.
                    </li>
                  </ul>
                  Please note that this limit resets at the starting of each quarter. 
                </div>
              </>
            )}

          </div>
        </div>
      ),
      onOk() { },
    });
  }
  function getUserInitials() {
    if (getUser?.name.split(" ")[1] === undefined) {
      return getUser?.name.split(" ")[0][0]
    } else {
      return getUser?.name.split(" ")[0][0].concat(getUser?.name.split(" ")[1][0])
    }
  }
  function createIcon() {
    const userInitial = getUserInitials();
    return <Avatar icon={userInitial} style={{ background: "#FA395C" }} />
  }
  const items: any = [
    {
      label: createIcon(),
      key: 'Logout',
      children: [
        {
          label: "Logout",
          key: "logout",
          onClick: logoutModal
        }
      ]
    }
  ]
  return (
    <>
      <ForgotPassword visible={modelVisible} onClose={() => setmodelVisible(false)} />
      <Row>
        <Col flex={"auto"}>
          <Button
            className="menu-button"
            shape={"circle"}
            type={"primary"}
            icon={<MenuOutlined />}
            onClick={() =>
              setSidebarVisible((dialog: boolean) => !dialog)
            }
          />
        </Col>
        <Col>
          <Button
            shape={"circle"}
            type={"primary"}
            onClick={info}
            icon={<InfoCircleOutlined />}
          />
        </Col>
        {/* <Col>
          <Divider type={"vertical"} />
        </Col>
        <Col>
          <Button shape={"circle"} type={"primary"} icon={<BellOutlined />} onClick={()=>navigate('/change-password')}/>
        </Col> */}

        <Col>
          <Menu mode={"horizontal"} title="Profile" items={items} />
        </Col>
      </Row>
    </>
  );
};

export default Navbar;

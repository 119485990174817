import { RootState } from "../store";

const announcementSelectors = {
  getAnnouncements: (state: RootState) => state.announcements.announcements,
  getAnnouncementTypes: (state: RootState) => state.announcements.announcementTypes,
  getannouncementLoader: (state: RootState) => state.announcements.loaders,
  getCelebrations: (state: RootState) => state.announcements.celebrations,
  getError: (state: RootState) => state.leave.error,
};

export default announcementSelectors;

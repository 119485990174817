import { lmsApiService } from "../../plugins/axios";
import {
  AnnouncementApiInterface,
  AnnouncementResources,
  CreateAnnouncementPayload,
  GetAnnouncementsPayload,
} from "./types";

const ANNOUNCEMENTS_ENDPOINTS: AnnouncementResources = {
  // Fetch Announcement type for announcement modal
  ANNOUNCEMENT_TYPES: {
    URL: "announcement-type", // ? date
    METHOD: "GET",
  },
  // Fetch announcement to show it on dashboard
  GET_ANNOUNCEMENTS: {
    URL: "show-announcements", // ? date
    METHOD: "GET",
  },
  // add announcement
  CREATE_ANNOUNCEMENT: {
    URL: "announcements", // ? date
    METHOD: "POST",
  },
  // get celebrations this months
  CELEBRATIONS: {
    URL: "/celebrations", // ? date
    METHOD: "GET",
  },
};

const AnnouncementApi: AnnouncementApiInterface = {
  getAnnouncements: async (payload: GetAnnouncementsPayload) => {
    const { response, error } = await lmsApiService({
      resource: ANNOUNCEMENTS_ENDPOINTS.GET_ANNOUNCEMENTS,
    });
    if (error) {
      return { error, announcements: null };
    }
    return {
      announcements: response.data,
      error: null,
    };
  },
  getAnnoucementTypes: async (payload: GetAnnouncementsPayload) => {
    const { response, error } = await lmsApiService({
      resource: ANNOUNCEMENTS_ENDPOINTS.ANNOUNCEMENT_TYPES,
    });
    if (error) {
      return { error, annoucementTypes: null };
    }
    return {
      annoucementTypes: response.data,
      error: null,
    };
  },
  createAnnouncement: async (data: CreateAnnouncementPayload) => {
    const { response, error } = await lmsApiService({
      resource: ANNOUNCEMENTS_ENDPOINTS.CREATE_ANNOUNCEMENT,
      options: {
        data,
      },
    });

    if (error) {
      return { error, Createannouncement: null };
    }
    return {
      Createannouncement: response.data,
      error: null,
    };
  },
  celebration: async (payload: GetAnnouncementsPayload) => {
    const { response, error } = await lmsApiService({
      resource: ANNOUNCEMENTS_ENDPOINTS.CELEBRATIONS,
    });

    if (error) {
      return { error, celebrations: null };
    }
    return {
      celebrations: response,
      error: null,
    };
  },
};

export default AnnouncementApi;

export { ANNOUNCEMENTS_ENDPOINTS };

import api from "./api";
import log from "./log";

export interface LMSServices {
  api: typeof api;
  log: typeof log;
}

const services: LMSServices = {
  api,
  log,
};
export default services;

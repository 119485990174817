import { MailOutlined } from '@ant-design/icons';
import {Col, Form, Input, Modal, Row, Typography} from 'antd'
import React, { FC } from 'react'
import { authActions, authSelectors } from '../../store/auth';
import { useAppDispatch, useAppSelector } from '../../store/hooks';

interface ForgotPasswordFormProps{
    visible: boolean,
    onClose: () => void
}
interface ForgotPasswordValues {
    email: string;
}

const ForgotPassword: FC<ForgotPasswordFormProps> = ({ visible, onClose}) => {
    const [forgotpasswordForm] = Form.useForm<ForgotPasswordValues>();
    const loader = useAppSelector(authSelectors.getIsFetching);
    const dispatch = useAppDispatch();
    const onFinish = (values: ForgotPasswordValues) => {
        dispatch(authActions.forgotPassword({
          email: values.email,
        }));
      };

  return (
    <Modal
    title="Reset Password"
    visible={visible}
      onOk={
         forgotpasswordForm.submit
      }
       confirmLoading={loader}
      okText = "Submit"
      onCancel={onClose}
    >
    <Form form={forgotpasswordForm} layout={"vertical"} onFinish={onFinish}>
    <Row gutter={[12,12]}>
          <Col span={8} >Email Id</Col>
          <Col span={16}>
          <Form.Item name="email" rules={[{ required: true }]}>
          <Input
                type="email"
                size="large"
                prefix={<MailOutlined className="site-form-item-icon" />}
                placeholder="Email"
                className="input"
              />
          </Form.Item>
          </Col>
          <Col>
            <Typography.Text style={{color:"#372C8B"}}> Note : Please Check your mail for password reset link.</Typography.Text> 
          </Col>
         
    </Row>
    </Form>
    </Modal>
  )
}

export default ForgotPassword
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {UserInterface} from "../../models/user";
import {actions} from "./actions";
import { UserActions } from "../users";
// >>>>>>> Auth State Types <<<<<<<<<
export interface AuthState {
  appSignature?: string | null;
  authToken?: string | null;
  refreshToken?: string | null;
  user?: UserInterface | null;
  isAuthenticated?: boolean; // These two states are managed in the auth reducer
  isFetching?: boolean; // so no need to manipulate it in the Api/Middleware .
  error?: any | null;
  
}

// >>>>>>> Auth Initial State <<<<<<<<<
const initialState: AuthState = {
  appSignature: null,
  authToken: null,
  refreshToken: null,
  isAuthenticated: false,
  user: null,
  isFetching: false,
  error: null,
 
};

// >>>>>>> Auth Slice <<<<<<<<<<
export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    // A reducer to reset Auth State
    resetAuthState: (state: AuthState) => {
      state.appSignature = null;
      state.authToken = null;
      state.refreshToken = null;
      state.user = null;
      state.isAuthenticated = false;
      state.isFetching = false;
      state.error = null;
    },
    setAuthToken: (state: AuthState, action: PayloadAction<string>) => {
      state.authToken = action.payload;
    },
    setAppSignature: (state: AuthState, action: PayloadAction<string>) => {
      state.appSignature = action.payload;
    },
    setAuthenticated: (state: AuthState, action: PayloadAction<boolean>) => {
      state.isAuthenticated = action.payload
    },
    toogleModal: (state: AuthState, action: PayloadAction<boolean>) => {
    }
  },
  extraReducers: {
    /**
     * Below reducers are written as extraReducers to prevent
     * new redux-toolkit complexities from being introduced
     * in our predefined buisness logics of Authentication
     */
    [actions.login.type]: (state,action) => {
      state.isFetching = true;
      state.error = action.payload.error;
    },
    [actions.loginSuccess.type]: (state, action) => {
      state.isFetching = false;
      state.isAuthenticated = true;
      state.authToken = action.payload.authToken;
      state.refreshToken = action.payload.refreshToken;
      state.user = action.payload.user;
    },
    [actions.loginFailure.type]: (state, action) => {
      state.error = action.payload.error;
      state.isFetching = false;
      state.isAuthenticated = false;
    },
    [actions.register.type]: (state,action) => {
      state.isFetching = true;
    },
    [actions.registerSuccess.type]: (state, action) => {
      state.isFetching = false;
    },
    [actions.registerFailure.type]: (state, action) => {
      state.error = action.payload.error;
    },
    [actions.logout.type]: state => {
      state.isFetching = true;
    },
    [actions.logoutSuccess.type]: (state,action) => {
      state.user = null;
      state.authToken = null;
      state.refreshToken = null;
      state.isAuthenticated = false;
      state.isFetching = false;
    },
    [actions.logoutFailure.type]: (state, action) => {
      state.isFetching = false;
      state.error = action.payload.error;
    },
    [actions.renewAccessToken.type]: (state) => {
      state.isFetching = true;
    },
    [actions.renewAccessTokenSuccess.type]: (state, action) => {
      state.isFetching = false;
      state.authToken = action.payload.authToken;
    },
    [actions.renewAccessTokenFailure.type]: (state, action) => {
      state.isFetching = false;
      state.error = action.payload.error;
      state.isAuthenticated = false;
    },
    [actions.forgotPassword.type]: (state) => {
      state.isFetching = true;
    },
    [actions.forgotPasswordSuccess.type]: (state, action) => {
      state.isFetching = false;
      // state.isAuthenticated = true;
      // state.authToken = action.payload.authToken;
      // state.refreshToken = action.payload.refreshToken;
      // state.user = action.payload.user;
    },
    [actions.forgotPasswordFailure.type]: (state) => {
      state.isFetching = false;
    },
    [actions.changePassword.type]: (state) => {
      state.isFetching = true;
    },
    [actions.changePasswordSuccess.type]: (state, action) => {
      state.isFetching = false;
      // state.isAuthenticated = true;
      // state.authToken = action.payload.authToken;
      // state.refreshToken = action.payload.refreshToken;
      // state.user = action.payload.user;
    },
    [actions.changePasswordFailure.type]: (state) => {
      state.isFetching = false;
    },
    [UserActions.getCurrentUserDetails.type]: state => {
      state.isFetching = true;
    },
    [UserActions.getCurrentUserDetailsSuccess.type]: (state, action) => {
      state.isFetching = false;
      state.user = action.payload.current_user;
    },
    [UserActions.getCurrentUserDetailsFailure.type]: (state, action) => {
      state.isFetching = false;
      state.error = action.payload.error;
    }
  }
});

const {resetAuthState, setAuthToken, setAppSignature, setAuthenticated} = authSlice.actions;

export default authSlice.reducer;
export {resetAuthState, setAuthToken, setAppSignature, setAuthenticated};

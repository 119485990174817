import React, { useEffect } from "react";
import { Avatar, Card, Divider, Empty, List, Space, Typography } from "antd";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  Announcementactions,
  announcementSelectors,
} from "../../store/announcements";
import { BadgeIcon, BirthdayCakeIcon } from "../UI/Icons";
import moment from "moment";
import { getRandomColor } from "../../utils/getRandomColor";

const CelebrationsCard = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(Announcementactions.celebrationDetails({}));
  }, [dispatch]);
  const celebrations = useAppSelector(announcementSelectors.getCelebrations);

  return (
    <Card
      title="Celebrations this month &#127881;"
      bodyStyle={{ paddingTop: 12 }}
      className={"h-100"}
      style={{ minHeight: "360px", maxHeight: "370px" }}
    >
      {celebrations.length > 0 ? (
        <List
          size="small"
          itemLayout="horizontal"
          className={"celebration-list"}
          dataSource={celebrations}
          renderItem={({ type, joining_date, name, birth_date }, index) =>
            (type === "Birthday" && (
              <List.Item key={`celebration_${name + index}`} className={"ps-0"} style={{minWidth:"325px"}}>
                <List.Item.Meta
                  avatar={
                    <Avatar style={{ backgroundColor: getRandomColor() }}>
                      {name
                        .split(" ")
                        .map((i) => i.charAt(0).toUpperCase())
                        .join("")
                        .substring(0, 2)}
                    </Avatar>
                  }
                  title={name}
                  description={
                    <Space split={<Divider type={"vertical"} />}>
                      <Typography.Text type={"secondary"}>
                        {moment(birth_date, "YYYY-MM-DD").format("DD MMM")}
                      </Typography.Text>
                      <Typography.Text type={"secondary"}>
                        Happy Birthday
                      </Typography.Text>
                    </Space>
                  }
                />
                <div>
                  <BirthdayCakeIcon
                    fill={
                      moment(birth_date, "YYYY-MM-DD").format("MM-DD") ===
                      moment().format("MM-DD")
                        ? "#372C8B"
                        : "#b0b0b0"
                    }
                  />
                </div>
              </List.Item>
            )) ||
            (type === "Work Anniversary" && (
              <List.Item key={`celebration_${name + index}`} className={"ps-0"} style={{minWidth:"325px"}}>
                <List.Item.Meta
                  avatar={
                    <Avatar style={{ backgroundColor: getRandomColor() }}>
                      {name
                        .split(" ")
                        .map((i) => i.charAt(0).toUpperCase())
                        .join("")
                        .substring(0, 2)}
                    </Avatar>
                  }
                  title={name}
                  description={
                    <Space split={<Divider type={"vertical"} />}>
                      <Typography.Text type={"secondary"}>
                        {moment(joining_date, "YYYY-MM-DD").format("DD MMM")}
                      </Typography.Text>
                      <Typography.Text type={"secondary"}>
                        Work Anniversary
                      </Typography.Text>
                    </Space>
                  }
                />
                <div>
                  <BadgeIcon
                    fill={
                      moment(joining_date, "YYYY-MM-DD").format("MM-DD") ===
                      moment().format("MM-DD")
                        ? "#372C8B"
                        : "#b0b0b0"
                    }
                  />
                </div>
              </List.Item>
            ))
          }
        />
      ) : (
        <Empty
          style={{ marginTop: "50px" }}
          description={"No celebration for this months!!"}
        />
      )}
    </Card>
  );
};

export default CelebrationsCard;

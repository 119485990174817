import React, { FC } from "react";
import { Progress, Skeleton, Typography } from "antd";
import "./style.less";
import { LeaveDetailInterface } from "../../../services/api/leaves/types";
import { useAppSelector } from "../../../store/hooks";
import { leaveSelectors } from "../../../store/leaves";

interface LeaveCardProps extends LeaveDetailInterface {}

const LeaveCard: FC<LeaveCardProps> = ({
  id,
  type,
  color,
  total,
  available,
  isUnlimited,
}) => {
  const leaveCardLoader:boolean = useAppSelector(leaveSelectors.getLeaveCardLoader);
  return (
    <div className="leave-card" style={{ background: "white" }}>
      {leaveCardLoader ? (
        <Skeleton  active avatar paragraph={{ rows: 2 }} />
      ) : (
        <>
          <Typography.Title
            level={4}
            className={"leave-card--title"}
            style={{ color: color }}
          >
            {type}
          </Typography.Title>
          <div className={"leave-card--content"}>
            <Progress
              type="circle"
              percent={isUnlimited ? 100 : (available * 100) / total}
              strokeColor={color}
              trailColor={"#DDDDDD"}
              width={60}
              status={"normal"}
              strokeWidth={8}
              style={{ color }}
              format={() => (
                <>
                  <Typography.Text style={{ fontSize: 18 }}>
                    {isUnlimited === 0 ? available : "∞"}
                  </Typography.Text>
                  <Typography.Text style={{ fontSize: 12 }}>
                    {" "}
                    / {isUnlimited === 0 ? total : "∞"}
                  </Typography.Text>
                </>
              )}
            />
            <div className={"leave-card--details"}>
              <Typography.Text>
                Available - {isUnlimited === 0 ? available : "∞"}{" "}
              </Typography.Text>
              <Typography.Text>Used - {total - available} </Typography.Text>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default LeaveCard;

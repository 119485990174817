import { lmsApiService } from "../../plugins/axios";
import {UserResources,UserApiInterface, UserDetailsUpdateDeletePayload, UserDetailsUpdatePayload} from "./types";

const USER_ENDPOINTS: UserResources = {
  // get current active user details on dashboard
  GET_CURRENT_USER_DETAILS: {
    URL: "/user-data", 
    METHOD: "GET"
  },
  // get all user details
  GET_USER_DETAILS: {
    URL: "/users/all", 
    METHOD: "GET"
  },
  // Update user details
  UPDATE_USER_DETAILS: {
    URL: "/users/:id", 
    METHOD: "PUT"
  },
  // delete users from system
  DELETE_USER: {
    URL: "/users/:id", 
    METHOD: "DELETE"
  },
  // get all roles for select any one in add new user modal
  USER_ROLE: {
    URL: "/user-roles", 
    METHOD: "GET"
  },
  // get all the role of users for Employee statistics on dashboard
  USER_STATISTICS: {
    URL: "user-statistics", 
    METHOD: "GET"
  },
  
};

const UserApi: UserApiInterface = {
  getCurrentUserDetails: async () => {
    const { response, error } = await lmsApiService({
      resource: USER_ENDPOINTS.GET_CURRENT_USER_DETAILS,      
    });
    if (error) {
      return { error, users: null }; 
    }
    return {
        current_user : response,
        error: null
    };
  },
  getUserDetails: async () => {
    const { response, error } = await lmsApiService({
      resource: USER_ENDPOINTS.GET_USER_DETAILS,
      
    });
    if (error) {
      return { error, users: null }; 
    }
   
    return {
        users : response,
        error: null
    };
  },
  updateUserDetails: async (data: UserDetailsUpdatePayload) => {
    const { response, error } = await lmsApiService({
      resource: USER_ENDPOINTS.UPDATE_USER_DETAILS,
      options:{
        data,
        pathVars:{
          id:data.id,
        }
      }
    });
    if (error) {
      return { error, users: null }; 
    }
    return {
        users : response,
      error: null
    };
  },
  deleteUserDetails: async (data: UserDetailsUpdateDeletePayload) => {
    const { response, error } = await lmsApiService({
      resource: USER_ENDPOINTS.DELETE_USER,
      options:{
        pathVars:{
          id:data.id,
        }
      }
    });
    if (error) {
      return { error, users: null }; 
    }
    return {
        users : response,
      error: null
    };
  },
  getUserRole: async () => {
    const { response, error } = await lmsApiService({
      resource: USER_ENDPOINTS.USER_ROLE,
      
    });
    if (error) {
      return { error, userRole: null }; 
    }
   
    return {
      userRole : response.data,
        error: null
    };
  },
  getUserStatistics: async () => {
    const { response, error } = await lmsApiService({
      resource: USER_ENDPOINTS.USER_STATISTICS,
      
    });
    if (error) {
      return { error, userStatistics: null }; 
    }
   
    return {
      userStatistics : response.data,
        error: null
    };
  },
  
};

export default UserApi;

export { USER_ENDPOINTS };

import { Col, Modal, Row, DatePicker, notification } from "antd";
import React, { FC } from "react";
import { LeaveInterface } from "../../../services/api/leaves/types";
import * as Excel from "exceljs";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { leaveActions, leaveSelectors } from "../../../store/leaves";
import * as FileSaver from "file-saver";
const { RangePicker } = DatePicker;

interface LeaveReportProps {
  visible: boolean;
  onClose: () => void;
}


const ExportLeaveReport: FC<LeaveReportProps> = ({ visible, onClose }) => {
  const dispatch = useAppDispatch();
  const downloadLeaveReport = useAppSelector(
    leaveSelectors.DownloadLeaveReport
  );

  function applyHeaderStyle(headers: any, sheet: any) {
    headers.forEach((key: any) => {
      sheet.getCell(key).fill = {
        type: "gradient",
        gradient: "angle",
        degree: 0,
        stops: [
          { position: 0, color: { argb: "d9f1fa" } },
          { position: 0.5, color: { argb: "d9f1fa" } },
          { position: 1, color: { argb: "d9f1fa" } },
        ],
      };
      sheet.getCell(key).alignment = {
        wrapText: true,
        vertical: "middle",
        horizontal: "center",
      };
      sheet.getCell(key).border = {
        right: { style: "thin" },
        top: { style: "thin" },
      };
      sheet.getCell(key).font = {
        name: "arial",
        size: 12,
      };
    });
    return sheet;
  }
  function applyRowStyle(sheet: any) {
    sheet.eachRow(function (row: any, rowNumber: any) {
      if (rowNumber > 3) {
        row.eachCell(function (cell: any, colNumber: any) {
          sheet.getCell(cell.address.toString()).alignment = {
            wrapText: true,
            vertical: "middle",
            horizontal: "center",
          };
          sheet.getCell(cell.address.toString()).border = {
            top: { style: "thin" },
            right: { style: "thin" },
            bottom: { style: "thin" },
            left: { style: "thin" },
          };
          sheet.getCell(cell.address.toString()).font = {
            name: "arial",
            size: 10,
          };
        });
      }
    });
    return sheet;
  }
  function exportExcel(data: LeaveInterface[]) {
    let workbook = new Excel.Workbook();
    workbook.creator = "Web";
    workbook.lastModifiedBy = "Web";
    workbook.created = new Date();
    workbook.modified = new Date();
    let columns = [
      "User",
      "Role",
      "Start Date",
      "End Date",
      "Leave Type",
      "Reaosn",
      "Adjustment Date",
      "Half Leave / Alternate Shift Time",
      "Days",
    ];

    workbook.addWorksheet("leave-report", {
      views: [
        {
          state: "frozen",
          ySplit: 3,
          activeCell: "A1",
        },
      ],
    });
    let sheet = workbook.getWorksheet(1);
    sheet.addRow(["Leave Report"]);
    sheet.mergeCells("A1:H2");
    sheet.addRow("");

    sheet.lastRow!.values = columns;
    sheet.columns = [
      { key: "name", width: 20 },
      { key: "start_date", width: 25 },
      { key: "end_date", width: 25 },
      { key: "req_type_id", width: 25 },
      { key: "reason", width: 50 },
      { key: "adjustment_date", width: 25 },
      { key: "half_leave_time", width: 25 },
      { key: "days", width: 15 },
    ];

    data.forEach((val) => {
      if (val.req_type_id===6) {
        let alternate_shift = sheet.addRow([
          val.user.name,
          val.user.role?.type,
          val.start_date,
          val.end_date,
          val.request_type.type,
          val.reason,
          val.adjustment_date,
          val.half_leave_time,
          val.days,
        ]);
        alternate_shift.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: +val.req_type_id===6 ? "ffffb3" : "000000" },
        };
        alternate_shift.border = {
          right: { style: "thin" },
          top: { style: "thin" },
          bottom: { style: "thin" },
          left: { style: "thin" },
        };
      } else if (val.is_emergency===1) {
        let emergency_leave = sheet.addRow([
          val.user.name,
          val.user.role?.type,
          val.start_date,
          val.end_date,
          val.request_type.type + "(Emergency)",
          val.reason,
          val.adjustment_date,
          val.half_leave_time,
          val.days,
        ]);
        emergency_leave.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: +val.is_emergency===1 ? "cef5d1" : "000000" },
        };
        emergency_leave.border = {
          right: { style: "thin" },
          top: { style: "thin" },
          bottom: { style: "thin" },
          left: { style: "thin" },
        };
      } else if (val.is_alternate_shift===1) {
        let is_alternate_shift = sheet.addRow([
          val.user.name,
          val.user.role?.type,
          val.start_date,
          val.end_date,
          val.request_type.type + "(Alternate shift)",
          val.reason,
          val.adjustment_date,
          val.half_leave_time,
          val.days,
        ]);
        is_alternate_shift.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: +val.is_alternate_shift===1 ? "ffffb3" : "000000" },
        };
        is_alternate_shift.border = {
          right: { style: "thin" },
          top: { style: "thin" },
          bottom: { style: "thin" },
          left: { style: "thin" },
        };
      } else if (val.is_half_leave===1) {
        let half_leave = sheet.addRow([
          val.user.name,
          val.user.role?.type,
          val.start_date,
          val.end_date,
          val.request_type.type + "(Half Leave)",
          val.reason,
          val.adjustment_date,
          val.half_leave_time,
          val.days,
        ]);
        half_leave.border = {
          right: { style: "thin" },
          top: { style: "thin" },
          bottom: { style: "thin" },
          left: { style: "thin" },
        };
      } else {
        let other_leave = sheet.addRow([
          val.user.name,
          val.user.role?.type,
          val.start_date,
          val.end_date,
          val.request_type.type,
          val.reason,
          val.adjustment_date,
          val.half_leave_time,
          val.days,
        ]);
        other_leave.border = {
          right: { style: "thin" },
          top: { style: "thin" },
          bottom: { style: "thin" },
          left: { style: "thin" },
        };
      }
    });

    sheet.getCell("A1").alignment = {
      wrapText: true,
      vertical: "middle",
      horizontal: "center",
    };
    sheet.getCell("A1").font = {
      name: "arial",
      size: 14,
    };

    let headers = ["A3", "B3", "C3", "D3", "E3", "F3", "G3", "H3"];
    sheet = applyHeaderStyle(headers, sheet);
    sheet = applyRowStyle(sheet);

    const blobType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: blobType });
      let name = "LeaveReport-" + new Date().toString() + ".xlsx";
      FileSaver.saveAs(blob, name, true);
    });
  }
  return (
    <Modal
      title="Download Leave Report"
      visible={visible}
      onOk={() => {
        exportExcel(downloadLeaveReport);
        notification.success({ message: "Download success !!" });
      }}
      okText="Download"
      onCancel={onClose}
    >
      <Row gutter={[12, 12]}>
        <Col span={6}>Duration : </Col>
        <Col span={18}>
          <RangePicker
            onChange={(val:any) => {
              if (val?.length === 2) {
                dispatch(
                  leaveActions.downloadLeaveReport({
                    start_date: val?.[0]?.format("YYYY-MM-DD") as string,
                    end_date: val?.[1]?.format("YYYY-MM-DD") as string,
                  })
                );
              }
            }}
          />
        </Col>
      </Row>
    </Modal>
  );
};

export default ExportLeaveReport;

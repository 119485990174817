import {call, getContext, put, takeLeading} from "redux-saga/effects";
import {actionTypes} from "./actions";
import {PayloadAction} from "@reduxjs/toolkit";
import {LMSApi} from "../../services/api";
import { actions } from './actions';
import { AttendanceReportPayload, GetHistoryPayload, UploadLogInterface } from "../../services/api/history/types";
import { message } from "antd";
import { HistoryState } from "./reducer";

const {
  getHistoryDetailsSuccess,
  getHistoryDetailsFailure,
  attendanceReportFailure,
  attendanceReportSuccess,
  uploadLogFailure,
  uploadLogSuccess
} = actions;

function* getHistoryFlow(action: PayloadAction<GetHistoryPayload>) {
  const api: LMSApi = yield getContext('api');
  const { historyDetails, error } = yield call(api.history.getHistory, action.payload);

  if (error) {
    yield put(getHistoryDetailsFailure({ historyDetails: null, error }));
  } else {
    yield put(getHistoryDetailsSuccess({ historyDetails, error: null }))
  }
}

function* attendanceReportFlow(action: PayloadAction<AttendanceReportPayload>) {
  const api: LMSApi = yield getContext('api');
  const { attendaceReport, error } = yield call(api.history.attendanceReport, action.payload);

  if (error) {
    yield put(attendanceReportFailure({ attendaceReport: null, error }));
  } else {
    yield put(attendanceReportSuccess({ attendaceReport, error: null }))
  }
}

function* uploadLogFlow(action: PayloadAction<UploadLogInterface>) {
  const api: LMSApi = yield getContext('api');
  const { attendaceReport, error } = yield call(api.history.uploadLogs, action.payload);

  if (error) {
    yield put(uploadLogFailure({ attendaceReport: null, error }));
  } else {
    yield put(uploadLogSuccess({ attendaceReport, error: null }))
  }
}


export default function* historySaga() {
  yield takeLeading(actionTypes.GetHistory,getHistoryFlow);
  yield takeLeading(actionTypes.AttendanceReport,attendanceReportFlow);
  yield takeLeading(actionTypes.UploadLog,uploadLogFlow);
  yield takeLeading(actionTypes.UploadLogFailure, function* (action: PayloadAction<HistoryState>) {
    yield message.error(action.payload.error?.message || "Something went wrong");
  });
  yield takeLeading(actionTypes.UploadLogSuccess, function* (action: PayloadAction<HistoryState>) {
    
    yield message.success(`file uploaded successfully`);
  });
}
import { RootState } from "../store";

const leaveSelectors = {
  getLeaveDetails: (state: RootState) => state.leave.leaveDetails,
  getLeavesList: (state: RootState) => state.leave.leaves,
  getYourLeaveList: (state: RootState) => state.leave.yourLeaves,
  getLeaveTypes: (state: RootState) => state.leave.leavesTypes,
  getSelectedLeave: (state: RootState) => state.leave.selectedLeave,
  getError: (state: RootState) => state.leave.error,
  getLeaveReport: (state: RootState) => state.leave.leaveReport,
  getLeaveCardLoader: (state: RootState) => state.leave.leaveCardLoader,
  getLeaveLoader: (state: RootState) => state.leave.loaders,
  onLeave: (state: RootState) => state.leave.onLeave,
  SelectedLeaveId: (state: RootState) => state.leave.selectedLeaveId,
  SelectLeaveRequestId: (state: RootState) => state.leave.selectLeaveRequestId,
  leaveModalVisible: (state: RootState) => state.leave.leaveModelVisible,
  updateLeaveModalVisible: (state: RootState) => state.leave.updateLeaveModelVisible,
  DownloadLeaveReport: (state: RootState) => state.leave.downloadLeaveReport,
};

export default leaveSelectors;

import { RootState } from "../store";

const userSelectors = {
  getUserDetails: (state: RootState) => state.user.users,
  getUserModelId: (state: RootState) => state.user.userModelId,
  getUserRoleSelector: (state: RootState) => state.user.userRole,
  getUserStatisticsSelector: (state: RootState) => state.user.userStatistics,
};

export default userSelectors;

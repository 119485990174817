import {createSlice} from "@reduxjs/toolkit";
import {Announcementactions} from "./actions";
import {LMSApiError} from "../../services/api/types";
import {
  AnnouncementInterface,
  CelebrationsInterface,
  AnnouncementTypeInterface
} from "../../services/api/announcements/types";

export interface AnnouncementState {
  announcements: AnnouncementInterface[],
  announcementTypes: AnnouncementTypeInterface[],
  celebrations: CelebrationsInterface[],
  loaders:  boolean,
  error: LMSApiError | null
}

const initialState: AnnouncementState = {
  announcements: [],
  announcementTypes:[],
  celebrations: [],
  error: null,
  loaders: false
}

const AnnouncementSlice = createSlice({
  name: 'announcement',
  initialState,
  reducers: {},
  extraReducers: {
    [Announcementactions.getAnnouncements.type]: state => {
      state.loaders = true;
    },
    [Announcementactions.getAnnouncementsSuccess.type]: (state, action) => {
      state.loaders = false;
      state.announcements = action.payload.announcements;
    },
    [Announcementactions.getAnnouncementsFailure.type]: (state, action) => {
      state.loaders = false;
      state.error = action.payload.error;
    },

    [Announcementactions.getAnnouncementTypes.type]: state => {
      state.loaders = true;
    },
    [Announcementactions.getAnnouncementTypesSuccess.type]: (state, action) => {
      state.loaders = false;
      state.announcementTypes = action.payload.annoucementTypes;
    },
    [Announcementactions.getAnnouncementTypesFailure.type]: (state, action) => {
      state.loaders = false;
      state.error = action.payload.error;
    },

    [Announcementactions.createAnnouncement.type]: state => {
      state.loaders = true;
    },
    [Announcementactions.createAnnouncementSuccess.type]: (state, action) => {
      state.loaders = false;
      state.announcementTypes = action.payload.createAnnouncement;
    },
    [Announcementactions.createAnnouncementFailure.type]: (state, action) => {
      state.loaders = false;
      state.error = action.payload.error;
    },

    [Announcementactions.celebrationDetails.type]: state => {
      state.loaders = true;
    },
    [Announcementactions.celebrationDetailsSuccess.type]: (state, action) => {
      state.loaders = false;
      state.celebrations = action.payload.celebrations;
    },
    [Announcementactions.celebrationDetailsFailure.type]: (state, action) => {
      state.loaders = false;
      state.error = action.payload.error;
    }
  }
})

export default AnnouncementSlice.reducer;
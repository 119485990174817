import {call, getContext, put, takeLeading} from "redux-saga/effects";
import {actionTypes, UserActions} from "./actions";
import {PayloadAction} from "@reduxjs/toolkit";
import {GetUserDetailsPayload, UserDetailsUpdateDeletePayload, UserDetailsUpdatePayload, UserRolePayload} from "../../services/api/user/types";
import {LMSApi} from "../../services/api";
import { AuthState } from "../auth/reducer";
import { notification } from "antd";

const {
  getCurrentUserDetailsSuccess,
  getCurrentUserDetailsFailure,
  getUserDetailsSuccess,
  getUserDetailsFailure,
  updateUserDetailsFailure,
  updateUserDetailsSuccess,
  deleteUserDetailsSuccess,
  deleteUserDetailsFailure,
  getUserRoleFailure,
  getUserRoleSuccess,
  getUserStatisticsFailure,
  getUserStatisticsSuccess

} = UserActions;

function* getCurrentUserDetailsFlow() {
  const api: LMSApi = yield getContext('api');
  const { current_user, error } = yield call(api.user.getCurrentUserDetails);
  if (error) {
    yield put(getCurrentUserDetailsFailure({ error }));
  } else {
    yield put(getCurrentUserDetailsSuccess({ current_user, error: null }))
  }
}

function* getUserDetailsFlow(action: PayloadAction<GetUserDetailsPayload>) {
  const api: LMSApi = yield getContext('api');
  const { users, error } = yield call(api.user.getUserDetails, action.payload);

  if (error) {
    yield put(getUserDetailsFailure({ users: null, error }));
  } else {
    yield put(getUserDetailsSuccess({ users, error: null }))
  }

}
function* updateUserdetailsFlow(action: PayloadAction<UserDetailsUpdatePayload>) {
  const api: LMSApi = yield getContext('api');
  const { users, error } = yield call(api.user.updateUserDetails, action.payload);

  if (error) {
    yield put(updateUserDetailsFailure({ users: null, error }));
  } else {
    yield put(updateUserDetailsSuccess({ users, error: null }))
  }

}
function* deleteUserDetailsFlow(action: PayloadAction<UserDetailsUpdateDeletePayload>) {
  const api: LMSApi = yield getContext('api');
  const { users, error } = yield call(api.user.deleteUserDetails, action.payload);

  if (error) {
    yield put(deleteUserDetailsFailure({ users: null, error }));
  } else {
    yield put(deleteUserDetailsSuccess({ users, error: null }))
  }

}
function* getUserRoleFlow(action: PayloadAction<UserRolePayload>) {
  const api: LMSApi = yield getContext('api');
  const { userRole, error } = yield call(api.user.getUserRole, action.payload);

  if (error) {
    yield put(getUserRoleFailure({ userRole: null, error }));
  } else {
    yield put(getUserRoleSuccess({ userRole, error: null }))
  }

}
function* getUserStatisticsFlow(action: PayloadAction<UserRolePayload>) {
  const api: LMSApi = yield getContext('api');
  const { userStatistics, error } = yield call(api.user.getUserStatistics, action.payload);

  if (error) {
    yield put(getUserStatisticsFailure({ userStatistics: null, error }));
  } else {
    yield put(getUserStatisticsSuccess({ userStatistics, error: null }))
  }

}




export default function* userSaga() {
  yield takeLeading(actionTypes.GetCurrentUserDetails, getCurrentUserDetailsFlow);
  yield takeLeading(actionTypes.GetUserDetails, getUserDetailsFlow);
  yield takeLeading(actionTypes.UpdateUserDetails, updateUserdetailsFlow);
  yield takeLeading(actionTypes.DeleteUserDetails, deleteUserDetailsFlow);
  yield takeLeading(actionTypes.GetUserRole, getUserRoleFlow);
  yield takeLeading(actionTypes.GetUserStatistics, getUserStatisticsFlow);
  yield takeLeading(actionTypes.DeleteUserDetailsSuccess, function* (action: PayloadAction<AuthState>) {
    yield notification.success({message: "Delete User Successfully" || "Something went wrong"})
  });
  yield takeLeading(actionTypes.DeleteUserDetailsFailure, function* (action: PayloadAction<AuthState>) {
    yield notification.error({message: action.payload.error?.message || "Something went wrong"})
  });

  yield takeLeading(actionTypes.UpdateUserDetailsSuccess, function* (action: PayloadAction<AuthState>) {
    yield notification.success({message: "Update User Successfully" || "Something went wrong"})
  });
  yield takeLeading(actionTypes.UpdateUserDetailsFailure, function* (action: PayloadAction<AuthState>) {
    yield notification.error({message: action.payload.error?.message || "Something went wrong"})
  });
}
import  { FC, useState } from "react";
import { Modal, Row, Col, Select, DatePicker, Checkbox, Form } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { leaveActions, leaveSelectors } from "../../../store/leaves";
import moment from "moment";
import { authSelectors } from "../../../store/auth";
const { Option } = Select;
interface LeaveRequestInterface {
  startDate: string;
  endDate: string;
  reason: string;
  status: string;
  type?: string;
  day: string;
  adjustmentDate?: string | null;
  halfLeaveTime: string;
  isEmergency: number ;
  isHalfLeave: number;
  shifttime: string;
}

const labelsCols = { xs: 12, md: 12, lg: 12 };
const fieldCols = { xs: 12, md: 12, lg: 12 };

interface UpdateLeaveRequestFormProps {
  reqId: number;
  start_date: string;
  end_date: string;
  reason: string;
  status_id?: number;
  typeId: number;
  type: string;
  days?: number;
  adjustment_date?: string;
  half_leave_time: string;
  is_emergency?: number;
  is_half_leave?: number;
  is_alternate_shift?: number;
}

const UpdateLeaveRequestForm: FC<UpdateLeaveRequestFormProps> = ({
  reqId,
  start_date,
  end_date,
  reason,
  typeId,
  type,
  adjustment_date,
  half_leave_time,
  is_emergency,
  is_half_leave,
  is_alternate_shift,
}) => {
  const dispatch = useAppDispatch();
  const leaveTypes = useAppSelector(leaveSelectors.getLeaveTypes);
  const leaveLoader = useAppSelector(leaveSelectors.getLeaveLoader);
  const selectedLeaveId = useAppSelector(leaveSelectors.SelectedLeaveId);
  const user = useAppSelector(authSelectors.getUser);

  const [halfday, setHalfday] = useState(is_half_leave);
  const [emergency, setEmergency] = useState(is_emergency);
  const [alternateShift, setAlternateShift] = useState(is_alternate_shift);
  const [leaveType, setLeaveType] = useState(typeId.toString());
  const [leaveForm] = Form.useForm();
  const updated_start_date = Form.useWatch('startDate', leaveForm);
  const updated_end_date = Form.useWatch('endDate', leaveForm);
  const Modalvisible = useAppSelector(leaveSelectors.updateLeaveModalVisible);
  const onFinish = (values: LeaveRequestInterface) => {
    dispatch(
      leaveActions.updateLeaveRequest({
        req_id: reqId,
        start_date: moment(values.startDate ?? start_date).format("YYYY-MM-DD"),
        end_date:
          (values.type === "Adjustment Leave" || (Number(values.type) === 4 || Number(values.type) === 10))
            ? moment(values.startDate ?? start_date).format("YYYY-MM-DD")
            : moment(values.endDate ?? end_date).format("YYYY-MM-DD"),
        is_emergency: values.isEmergency ?? is_emergency,
        is_half_leave: values.isHalfLeave ?? is_half_leave,
        is_alternate_shift : alternateShift ?? is_alternate_shift,
        half_leave_time:
          values.shifttime ?? values.halfLeaveTime ?? "",
        adjustment_date: moment(
          values?.adjustmentDate ?? adjustment_date
        ).format("YYYY-MM-DD"),
        reason: values.reason ?? reason,
        type: leaveType! ?? typeId,
      })
    );
    dispatch(leaveActions.updateLeaveModelVisible({ visible: false }));
    dispatch(leaveActions.getYourLeaves({}));
    
  };

  return (
    <>
      {selectedLeaveId===reqId && (
        <Modal
          title={"Update Leave Request Details "}
          visible={Modalvisible}
          onOk={leaveForm.submit}
          confirmLoading={leaveLoader}
          okText="Update"
          onCancel={() =>
            dispatch(leaveActions.updateLeaveModelVisible({ visible: false }))
          }
        >
          <Form form={leaveForm} layout={"vertical"} initialValues={{
              type:type,
              startDate:moment(start_date, "YYYY-MM-DD"),
              endDate:moment(end_date, "YYYY-MM-DD"),
              halfLeaveTime:half_leave_time,
              adjustmentDate:adjustment_date !== null ? moment(adjustment_date, "YYYY-MM-DD") : moment(),
              reason:reason,
              shifttime:half_leave_time
            }} onFinish={onFinish}>
            <Row>
              <Col {...labelsCols}>Type </Col>
              <Col {...fieldCols}>
                <Form.Item name="type" style={{ width: "inherit" }}>
                  <Select
                    onChange={(type) => {
                      console.log(type)
                      setLeaveType(type.toString());
                      leaveForm.setFieldsValue({ type: type.toString() });
                    }}
                    showSearch
                    filterOption={(input, option) =>
                      (option!.children as unknown as string)
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {leaveTypes?.map((leave) =>
                      !user?.is_training && leave.id !==7 ? (
                        <Option key={leave.id} >
                          {leave.type}
                        </Option>
                      ) : (
                        (leave.id===4 || leave.id===5 || leave.id===6) && (
                          <Option key={leave.id} >
                            {leave.type}
                          </Option>
                        )
                      )
                    )}
                  </Select>
                </Form.Item>
              </Col>

              {(leaveType === "1" ||
                leaveType === "3" ||
                leaveType === "4" ||
                leaveType === "10") && (
                <>
                  <Col {...labelsCols}>Emergency day </Col>
                  <Col {...fieldCols}>
                    <Form.Item name="isEmergency">
                      <Checkbox
                        defaultChecked={is_emergency ===1 ? true : false}
                        checked={emergency === 1 ?true :false}
                        onChange={(val) => {
                          setEmergency(val.target.checked === true ? 1 : 0);
                          leaveForm.setFieldsValue({
                            isEmergency: val.target.checked === true ? 1 : 0,
                          });
                        }}
                      ></Checkbox>
                    </Form.Item>
                  </Col>
                </>
              )}
              {([1, 2, 3, 4, 5, 10].includes(Number(leaveType)) && ((updated_start_date && updated_end_date) ? (updated_end_date?.diff(updated_start_date, 'days') === 0) : true)) && (
                <>
                  <Col {...labelsCols}>Half day </Col>
                  <Col {...fieldCols}>
                    <Form.Item name="isHalfLeave">
                      <Checkbox
                        defaultChecked={is_half_leave ? true : false}
                        checked={halfday === 1 ? true : false}
                        onChange={(value) => {
                          setHalfday(value.target.checked === true ? 1 :0);
                          leaveForm.setFieldsValue({
                            isHalfLeave: value.target.checked === true ? 1 : 0,
                          });
                        }}
                      ></Checkbox>
                    </Form.Item>
                  </Col>
                </>
              )}
              {halfday===1 && (
                <>
                  <Col {...labelsCols}>Alternate shift </Col>
                  <Col {...fieldCols}>
                    <Form.Item name="isAlternateShift">
                      <Checkbox
                        defaultChecked={is_alternate_shift===1 ? true : false}
                        checked={alternateShift === 1 ? true : false}
                        onChange={(value) => {
                          setAlternateShift(value.target.checked === true ? 1 : 0);
                          leaveForm.setFieldsValue({
                            isAlternateShift: value.target.checked === true ? 1 :0,
                          });
                        }}
                      ></Checkbox>
                    </Form.Item>
                  </Col>
                </>
              )}
              {[4, 10].includes(Number(leaveType)) ? (
                //When taking adjustment leave, start_date should be replaced by its name leave date.
                <Col {...labelsCols}>Leave Date</Col>
              ) : (
                <Col {...labelsCols}>Start Date</Col>
              )}
              <Col {...fieldCols}>
                <Form.Item name="startDate" rules={[{ required: true }]}>
                  <DatePicker
                    inputReadOnly={true}
                    placeholder="Select start date"
                    format="YYYY-MM-DD"
                    disabledDate={(current) =>
                      // Disable dates that are Saturdays or Sundays
                      (current &&
                        (current.day() === 6 || current.day() === 0)) ||
                      // Add your other conditions here
                      (!current ||
                      (user?.is_training === false &&
                        emergency === 0 &&
                        leaveType !== "2" &&
                        leaveType !== "6")
                        ? current.isSameOrBefore(
                            moment().format("LT") <= "12:30 PM"
                              ? moment().add(0, "days")
                              : moment().add(1, "days")
                          )
                        : (user?.is_training === true &&
                            emergency === 0 &&
                            leaveType !== "5" &&
                            leaveType !== "6" &&
                            current.isSameOrBefore(
                              moment().format("LT") <= "12:30 PM"
                                ? moment().add(0, "days")
                                : moment().add(1, "days")
                            )) ||
                          current.isBefore(moment().add(-1, "days")))
                    }
                  />
                </Form.Item>
              </Col>

              {!([4, 10].includes(Number(leaveType))) && (
                //End_date should be hidden if more than 1 leave is taken in adjustment leave
                <>
                  <Col {...labelsCols}>End Date </Col>
                  <Col {...fieldCols}>
                    <Form.Item name="endDate" rules={[{ required: true }]}>
                      <DatePicker
                        inputReadOnly={true}
                        placeholder="Select end date"
                        format="YYYY-MM-DD"
                        disabledDate={(current) =>
                          // Disable dates that are Saturdays or Sundays
                          (current &&
                            (current.day() === 6 || current.day() === 0)) ||
                          // Add your other conditions here
                          (!current ||
                          (user?.is_training === false &&
                            emergency === 0 &&
                            leaveType !== "2" &&
                            leaveType !== "6")
                            ? current.isSameOrBefore(
                                moment().format("LT") <= "12:30 PM"
                                  ? moment().add(0, "days")
                                  : moment().add(1, "days")
                              )
                            : (user?.is_training === true &&
                                emergency === 0 &&
                                leaveType !== "5" &&
                                leaveType !== "6" &&
                                current.isSameOrBefore(
                                  moment().format("LT") <= "12:30 PM"
                                    ? moment().add(0, "days")
                                    : moment().add(1, "days")
                                )) ||
                              current.isBefore(moment().add(-1, "days")))
                        }
                      />
                    </Form.Item>
                  </Col>
                </>
              )}
              {(halfday === 1 && ((updated_start_date && updated_end_date) ? (updated_end_date?.diff(updated_start_date, 'days') === 0) : true)) && (
                <>
                  <Col {...labelsCols}>Leave Time </Col>
                  <Col {...fieldCols}>
                    <Form.Item name="halfLeaveTime"
                      rules={[
                        { required: alternateShift === 1 ?true :false,
                          message: "Select time"
                        },
                        ]}
                    >
                      <Select
                       placeholder={"Select Time"}
                        onChange={(val) => {
                          leaveForm.setFieldsValue({ halfLeaveTime: val });
                        }}
                      >
                        {alternateShift === 1 ? (
                      <>
                        <Option value="08:30 AM to 12:30 PM">
                          08:30 AM to 12:30 PM
                        </Option>
                        <Option value="12:30 PM to 04:30 PM">
                          12:30 PM to 04:30 PM
                        </Option>
                        <Option value="04:30 PM to 08:30 PM">
                          04:30 PM to 08:30 PM
                        </Option>
                      </>
                    ) : (
                      <>
                        <Option value="10:30 AM to 02:30 PM">
                          10:30 AM to 02:30 PM
                        </Option>
                        <Option value="02:30 PM to 06:30 PM">
                          02:30 PM to 06:30 PM
                        </Option>
                      </>
                    )}
                      </Select>
                    </Form.Item>
                  </Col>
                </>
              )}
              {(leaveType==="4" || leaveType==="10") ? (
                <>
                  <Col {...labelsCols}> Adjustment Date </Col>
                  <Col {...fieldCols}>
                    <Form.Item name="adjustmentDate">
                      <DatePicker
                        inputReadOnly={true}
                        placeholder="Select Adjustment date"
                        format="YYYY-MM-DD"
                        disabledDate={(d: any) =>
                          !d ||
                          // Check if the date is Sunday (day 0)
                          d.isBefore(moment().add(-1, "days")) ||
                          // Disable all days that are not Saturdays
                          d.day() !== 6
                        }
                      />
                    </Form.Item>
                  </Col>
                </>
              ) : null}
              <Col {...labelsCols}>Reason </Col>
              <Col {...fieldCols}>
                <Form.Item name="reason">
                  <TextArea
                    placeholder="Please write valid reason "
                    autoSize={{ minRows: 4, maxRows: 6 }}
                  />
                </Form.Item>
              </Col>
              {leaveType==="6" && (
                <>
                  <Col {...labelsCols}>Shift Time </Col>
                  <Col {...fieldCols}>
                    <Form.Item name="shifttime">
                      <Select
                        onChange={(val) => {
                          leaveForm.setFieldsValue({ shifttime: val });
                        }}
                      >
                        <Option value="08:30 to 04:30">08:30 to 04:30</Option>
                        <Option value="12:30 to 08:30">12:30 to 08:30</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </>
              )}
            </Row>
          </Form>
        </Modal>
      )}
    </>
  );
};

export default UpdateLeaveRequestForm;

import React, { useEffect, useState } from "react";
import { Button, Col, Form, Input, Row } from "antd";
import { LockOutlined, MailOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { authActions, authSelectors } from "../../store/auth";
import ForgotPassword from "./ForgotPassword";

interface LoginFormValues {
  email: string;
  password: string;
}

const LoginForm = () => {
  const [loginForm] = Form.useForm<LoginFormValues>();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const isAuthenticated = useAppSelector(authSelectors.getIsAuthenticated);

  const onFinish = (values: LoginFormValues) => {
    dispatch(
      authActions.login({
        email: values.email,
        password: values.password,
      })
    );
  };
  useEffect(() => {
    if (isAuthenticated) {
      navigate("/dashboard");
    }
  }, [isAuthenticated,navigate]);
  const [modelVisible, setmodelVisible] = useState(false);
  return (
    <div className={"form-wrapper"}>
      <ForgotPassword
        visible={modelVisible}
        onClose={() => setmodelVisible(false)}
      />
      <Form
        form={loginForm}
        name="horizontal_login"
        layout={"vertical"}
        onFinish={onFinish}
      >
        <Row>
          <Col span={24}>
            <Form.Item
              name="email"
              label={"Email"}
              rules={[
                {
                  required: true,
                  message: "Please input your email id!",
                },
              ]}
            >
              <Input
                type="email"
                size="large"
                prefix={<MailOutlined className="site-form-item-icon" />}
                placeholder="Email"
                className="input"
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label={"Password"}
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}
            >
              <Input.Password
                type="password"
                size="large"
                prefix={<LockOutlined className="site-form-item-icon" />}
                placeholder="Password"
                className="input"
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Row justify={"end"}>
              <Col>
                <Button
                  type={"text"}
                  style={{ color: "#F4326C" }}
                  onClick={() => setmodelVisible(true)}
                >
                  Forgot Password ??
                </Button>
              </Col>
            </Row>
          </Col>
          <Col span={24} className={"mt-3"}>
            <Button size="large" type="primary" block htmlType={"submit"}>
              Login
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default LoginForm;

import {createSlice} from "@reduxjs/toolkit";
import { UserInterface } from "../../models/user";
import {LMSApiError} from "../../services/api/types";
import { UserRoleInterface, UserStatisticsInterface, USER_LOADERS } from "../../services/api/user/types";
import { UserActions } from "./actions";



export interface UserState {
  users: UserInterface[]
  userModelId:number;
  loaders: Record<USER_LOADERS, boolean>,
  error: LMSApiError | null
  userRole:UserRoleInterface[];
  userStatistics:UserStatisticsInterface[];
}

const initialState: UserState = {
  users:[],
  userModelId:0,
  error: null,
  loaders: {
    GET_USER_DETAILS: true    
  },
  userRole:[],
  userStatistics:[]
}

const userSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
  },
  extraReducers: {
    [UserActions.getUserDetails.type]: state => {
      state.loaders.GET_USER_DETAILS = true;
    },
    [UserActions.getUserDetailsSuccess.type]: (state, action) => {
      state.loaders.GET_USER_DETAILS = false;
      state.users = action.payload.users;
    },
    [UserActions.getUserDetailsFailure.type]: (state, action) => {
      state.loaders.GET_USER_DETAILS = false;
      state.error = action.payload.error;
    },

    [UserActions.updateUserDetails.type]: state => {
      state.loaders.GET_USER_DETAILS = true;
    },
    [UserActions.updateUserDetailsSuccess.type]: (state, action) => {
      state.loaders.GET_USER_DETAILS = false;
      // state.users = action.payload.users;
    },
    [UserActions.updateUserDetailsFailure.type]: (state, action) => {
      state.loaders.GET_USER_DETAILS = false;
      state.error = action.payload.error;
    },

    [UserActions.deleteUserDetails.type]: state => {
      state.loaders.GET_USER_DETAILS = true;
    },
    [UserActions.deleteUserDetailsSuccess.type]: (state, action) => {
      state.loaders.GET_USER_DETAILS = false;
      // state.users = action.payload.users;
    },
    [UserActions.deleteUserDetailsFailure.type]: (state, action) => {
      state.loaders.GET_USER_DETAILS = false;
      state.error = action.payload.error;
    },

    [UserActions.UserModelId.type]: (state,action) => {
      state.userModelId = action.payload.id;
    },
    [UserActions.getUserRole.type]: (state, action) => {
      state.loaders.GET_USER_DETAILS = true;
      // state.users = action.payload.users;
    },
    [UserActions.getUserRoleFailure.type]: (state, action) => {
      state.loaders.GET_USER_DETAILS = false;
      state.error = action.payload.error;
    },

    [UserActions.getUserRoleSuccess.type]: (state,action) => {
      state.userRole = action.payload.userRole
    },

    [UserActions.getUserStatistics.type]: (state, action) => {
      state.loaders.GET_USER_DETAILS = true;
      // state.users = action.payload.users;
    },
    [UserActions.getUserStatisticsFailure.type]: (state, action) => {
      state.loaders.GET_USER_DETAILS = false;
      state.error = action.payload.error;
    },

    [UserActions.getUserStatisticsSuccess.type]: (state,action) => {
      state.userStatistics = action.payload.userStatistics
    },
  }
});

export default userSlice.reducer;

// const {setSelectedLeave} = leaveSlice.actions;

// export {setSelectedLeave};
import { createSlice } from "@reduxjs/toolkit";
import { leaveActions } from "./actions";
import {
  LeaveDetailInterface,
  LeaveInterface,
  YourLeaveInterface,
  LeaveTypeInterface,
  LeaveReportInterface,
  LeaveRequestStatusInterface,
} from "../../services/api/leaves/types";
import { LMSApiError } from "../../services/api/types";


export interface LeaveState {
  leavesTypes: LeaveTypeInterface[];
  leaves: LeaveInterface[];
  leaveDetails: LeaveDetailInterface[]; // TODO: refactor structure when backend is done,
  yourLeaves: YourLeaveInterface[];
  onLeave: LeaveInterface[];
  selectedLeave: LeaveInterface | null;
  leaveCardLoader:boolean;
  loaders: boolean;
  selectedLeaveId:number;
  selectLeaveRequestId:LeaveRequestStatusInterface[];
  leaveModelVisible:boolean;
  updateLeaveModelVisible:boolean;
  error: LMSApiError | null;
  leaveReport:LeaveReportInterface[];
  downloadLeaveReport:LeaveInterface[];
  leaveAction:string
}

const initialState: LeaveState = {
  leaveDetails: [],
  onLeave:[],
  leaves: [],
  yourLeaves: [],
  selectedLeave: null,
  leavesTypes: [],
  error: null,
  leaveCardLoader: false,
  loaders: false,
  leaveReport:[],
  selectedLeaveId:0,
  selectLeaveRequestId:[],
  leaveModelVisible:false,
  updateLeaveModelVisible:false,
  downloadLeaveReport:[],
  leaveAction:""
};

const leaveSlice = createSlice({
  name: "leave",
  initialState,
  reducers: {
    // setSelectedLeave: (state:, action: PayloadAction<LeaveInterface | null>) => {
    //   state.selectedLeave = action.payload;
    // }
  },
  extraReducers: {
    [leaveActions.getLeaveDetails.type]: (state) => {
      state.leaveCardLoader = true;
    },
    [leaveActions.getLeaveDetailsSuccess.type]: (state, action) => {
      state.leaveCardLoader = false;
      state.leaveDetails = action.payload.leaveDetails;
    },
    [leaveActions.getLeaveDetailsFailure.type]: (state, action) => {
      state.leaveCardLoader = false;
      state.error = action.payload.error;
    },
    [leaveActions.getLeaverequests.type]: (state) => {
      state.loaders = true;
    },
    [leaveActions.getLeaverequestsSuccess.type]: (state, action) => {
      state.loaders = false;
      state.leaves = action.payload.leave;
    },
    [leaveActions.getLeaverequestsFailure.type]: (state, action) => {
      state.loaders = false;
      state.error = action.payload.error;
    },
    [leaveActions.getYourLeaves.type]: (state) => {
      state.loaders = true;
    },
    [leaveActions.getYourLeavesSuccess.type]: (state, action) => {
      state.loaders = false;
      state.yourLeaves = action.payload.yourLeave;
    },
    [leaveActions.getYourLeavesFailure.type]: (state, action) => {
      state.loaders = false;
      state.error = action.payload.error;
    },
    [leaveActions.getLeaveTypes.type]: (state) => {
      state.loaders = true;
    },
    [leaveActions.getLeaveTypesSuccess.type]: (state, action) => {
      state.loaders = false;
      state.leavesTypes = action.payload.leavesTypes;
    },
    [leaveActions.getLeaveTypesFailure.type]: (state, action) => {
      state.loaders = false;
    },
    [leaveActions.leaveAction.type]: (state, action) => {
      state.loaders = true;
    },
    [leaveActions.leaveActionSuccess.type]: (state, action) => {
      state.loaders = false;
      state.leaveAction = action.payload.leaveAction
    },
    [leaveActions.leaveActionFailure.type]: (state, action) => {
      state.loaders = false;
      state.error = action.payload.error;
    },
    [leaveActions.makeLeaveRequest.type]: (state, action) => {
      state.loaders= true;
    },
    [leaveActions.makeLeaveRequestSuccess.type]: (state, action) => {
      state.loaders =false;
    },
    [leaveActions.makeLeaveRequestFailure.type]: (state, action) => {
      state.loaders = false;
      state.error = action.payload.error;
    },
    [leaveActions.leaveReport.type]: (state) => {
      state.loaders = true;
      state.leaveCardLoader = true;
    },
    [leaveActions.leaveReportSuccess.type]: (state, action) => {
      state.loaders = false;
      state.leaveCardLoader = false;
      state.leaveReport =action.payload.leaveReport;
    },
    [leaveActions.leaveReportFailure.type]: (state, action) => {
      state.loaders = false;
      state.leaveCardLoader = false;
      state.error = action.payload.error;
    },

    [leaveActions.downloadLeaveReport.type]: (state) => {
      state.loaders = true;
    },
    [leaveActions.downloadLeaveReportSuccess.type]: (state, action) => {
      state.loaders = false;
      state.downloadLeaveReport =action.payload.leaveReport;
    },
    [leaveActions.downloadLeaveReportFailure.type]: (state, action) => {
      state.loaders = false;
      state.error = action.payload.error;
    },

    [leaveActions.whoIsOnLeave.type]: (state) => {
      state.loaders = true;
    },
    [leaveActions.whoIsOnLeaveSuccess.type]: (state, action) => {
      state.loaders = false;
      state.onLeave =action.payload.onLeave;
    },
    [leaveActions.whoIsOnLeaveFailure.type]: (state, action) => {
      state.loaders = false;
      state.error = action.payload.error;
    },

    [leaveActions.deleteLeaveRequest.type]: (state) => {
      state.loaders = true;
    },
    [leaveActions.deleteLeaveRequestSuccess.type]: (state, action) => {
      state.loaders = false;
      // state.deleteRequest =action.payload.deleteRequest;
    },
    [leaveActions.deleteLeaveRequestFailure.type]: (state, action) => {
      state.loaders = false;
      state.error = action.payload.error;
    },

    [leaveActions.updateLeaveRequest.type]: (state) => {
      state.loaders = true;
    },
    [leaveActions.updateLeaveRequestSuccess.type]: (state, action) => {
      state.loaders = false;
    },
    [leaveActions.updateLeaveRequestFailure.type]: (state, action) => {
      state.loaders = false;
    },

    [leaveActions.selectedLeaveId.type]: (state, action) => {
      state.selectedLeaveId=action.payload.id
    },

    [leaveActions.selectLeaveRequestId.type]: (state, action) => {
      state.selectLeaveRequestId =[{"id":action.payload.id,"status":action.payload.status}]
    },

    [leaveActions.leaveModelVisible.type]: (state, action) => {
      state.leaveModelVisible=action.payload.visible
    },
    
    [leaveActions.updateLeaveModelVisible.type]: (state, action) => {
      state.updateLeaveModelVisible=action.payload.visible
    },
   
  },
});

export default leaveSlice.reducer;

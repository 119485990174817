import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export interface UIState {
  sideBarCollapsed: boolean
}

const initialState: UIState = {
  sideBarCollapsed: false,
}

const UISlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    toggleSidebarCollapse: (state, action: PayloadAction<boolean>) => {
      state.sideBarCollapsed = action.payload;
    }
  }
});

export default UISlice.reducer;

const {toggleSidebarCollapse} = UISlice.actions;

export {toggleSidebarCollapse};
import { Card, Tabs } from "antd";
import React, { useEffect } from "react";
import { useAppDispatch } from "../../store/hooks";
import { UserActions } from "../../store/users";
import AttendanceSection from "./attendanceSection";
import LeaveRequestSection from "./leaveRequestSection";
const { TabPane } = Tabs;
function Report() {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(UserActions.getUserDetails({}));
  }, [dispatch]);

  return (
    <Card title="Reports" style={{ overflow: "auto" }}>
     <Tabs defaultActiveKey="1">
        {/* Attendance Section */}
        <TabPane tab="ATTENDANCE" key="1">
          <AttendanceSection></AttendanceSection>
        </TabPane>
        {/* Leave Request Section */}
        <TabPane tab="LEAVE REQUESTS" key="2">
          <LeaveRequestSection></LeaveRequestSection>
        </TabPane>
      </Tabs>
    </Card>
  );
}

export default Report;

import {all, fork} from "redux-saga/effects";
import {authSaga} from "./auth";
import {leaveSaga} from './leaves';
import {announcementSaga} from './announcements';
import { historySaga } from "./history";
import { userSaga } from "./users";

export function* rootSaga() {
  yield all([fork(authSaga)]);
  yield all([fork(leaveSaga)]);
  yield all([fork(announcementSaga)]);
  yield all([fork(historySaga)]);
  yield all([fork(userSaga)]);
}

import {combineReducers} from "redux";
import {authReducer} from "./auth";
import {leaveReducer} from "./leaves";
import {announcementReducer} from "./announcements";
import {historyReducer} from "./history";
import {uiReducer} from "./ui";
import {userReducer} from "./users";

export const rootReducer = combineReducers({
  auth: authReducer,
  leave: leaveReducer,
  announcements: announcementReducer,
  history: historyReducer,
  ui: uiReducer,
  user :userReducer
});

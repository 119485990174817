import React from 'react';
import {Pie,PieConfig} from '@ant-design/plots';
import { Card, Empty } from 'antd';
import { useAppSelector } from '../../store/hooks';
import { userSelectors } from '../../store/users';


const PieChart = () => {
  const data = useAppSelector(userSelectors.getUserStatisticsSelector);
  
  const config:PieConfig = {
    appendPadding: 15,
    data,
    angleField: 'value',
    colorField: 'type',
    radius: 1,
    innerRadius: 0.5,
    label: {
      type: 'inner',
      offset: '-50%',
      style: {
        textAlign: 'center',
      },
      content: '{value}',
      autoRotate: false,
    },
    statistic: {
      title: false,
      content: {
        offsetY: 4,
        style: {
        textAlign: 'center',
        fontSize: '24px',
      },
    },
  }
}
  return (
    <Card title="Employee Statistics" className={'h-100'} bodyStyle={{paddingTop: 12}}>
      {data.length > 0
      ?
      <Pie {...config} legend={ {
        layout: 'vertical',
        position: window.screen.width>=768 ? 'right' : 'bottom',
        
      }} />
      :
      <Empty style={{marginTop:"80px"}} description="No statistics found !! "/>
        }
    </Card>
  )
};

// ReactDOM.render(<DemoPie />, document.getElementById('container'));

export default PieChart;
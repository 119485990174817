import { Card } from 'antd'
import React from 'react'
import YourLeaveList from '../Leaves/yourLeaveList'

function YourLeave() {
  return (
    <Card title="Your Leave" className={'h-100'} bodyStyle={{paddingTop: 12,overflow:"auto"}}>
    <YourLeaveList/>
    </Card>
  )
}

export default YourLeave
// Base and User model interfaces
export interface UserInterface {
  id: number;
  name: string;
  email: string;
  address:string;
  phone_number: string;
  scope: string;
  is_training:boolean;
  birth_dat?:string;
  joining_date?:string;
  status ?:number;
  role ?: string | null;
  role_id ?: number | null;
  training_period ?:number;
  gender?:string;
}

//User Model
export default function User(userData: UserInterface): UserInterface {
  return {
    id: userData.id || 0,
    name: userData.name || "",
    email: userData.email || "",
    address: userData.address || "",
    phone_number: userData.phone_number || "",
    scope:userData.scope||"",
    is_training:userData.is_training||false,
    gender: userData.gender || "M"
  };
}
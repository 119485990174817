import React, { useEffect, useState } from "react";
import {
  Card,
  Table,
  Button,
  Space,
  TableColumnsType,
  Modal,
  Typography,
  Tooltip,
  Input
} from "antd";

import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { UserActions, userSelectors } from "../../store/users";
import { UserInterface } from "../../models/user";
import EditUserDetailsForm from "./EditUserDetailsForm";
import RegisterForm from "../Register/RegisterForm";
import { leaveActions } from "../../store/leaves";
const { confirm } = Modal;
const { Search } = Input;

function UserDetails() {
  const [modelVisible, setmodelVisible] = useState(false);
  const [searchedTerm, setSearchedTerm] = useState('');

  const DeleteUserModal = (userId: number, userName: string) => {
    confirm({
      title: "Are you sure want to delete " + userName + " record ??",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        dispatch(
          UserActions.deleteUserDetails({
            id: userId,
          })
        );
        dispatch(UserActions.getUserDetails({}));
      },
    });
  };

  const dispatch = useAppDispatch();
  const columns: TableColumnsType<UserInterface> = [
    {
      title: "Action",
      key: "action",
      // className: 'd-flex justify-content-center',
      render: (_, user) => {
        return (
          <Space>
            <EditUserDetailsForm
              setSearchedTerm={setSearchedTerm}
              user_id={user.id}
              roleId={user.role_id}
              trainingPeriod={user.training_period}
              visible={modelVisible}
              onClose={() => setmodelVisible(false)}
              userName={user.name}
              email={user.email}
              phoneNo={user.phone_number}
              address={user.address}
              emp_date={user.joining_date}
              status={user.status!}
            />
            <Button
              className={"ant-btn-success"}
              size={"middle"}
              icon={<EditOutlined />}
              title="Update"
              onClick={() => {
                setmodelVisible(true);
                dispatch(UserActions.UserModelId({
                  id: user.id
                }));
              }}
            />
            <Button
              className={"ant-btn-danger"}
              size={"middle"}
              icon={<DeleteOutlined />}
              title="Delete"
              onClick={() => {
                DeleteUserModal(user.id, user.name);
              }}
            />
          </Space>
        );
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (_, user) => (
        <>
          <Tooltip color="#F4326C" placement="topLeft" title={user.status === 1 ? "Active user" : "De-Active Account"}>
            <Typography.Text style={{ color: user.status === 1 ? 'black' : '#F4326C' }}>
              {user.name}
            </Typography.Text>
          </Tooltip>
        </>
      ),
      sorter: (a: UserInterface, b: UserInterface) =>
        a.name.localeCompare(b.name),
    },
    {
      title: "Mobile number",
      dataIndex: "phone_number",
      key: "phone_number",
      sorter: (a: UserInterface, b: UserInterface) =>
        a.phone_number.localeCompare(b.phone_number),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      sorter: (a: UserInterface, b: UserInterface) =>
        a.email.localeCompare(b.email),
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Employment Date",
      dataIndex: "joining_date",
      key: "joining_date",
      sorter: (a: UserInterface, b: UserInterface) => {
        if (a.joining_date === undefined || a.joining_date === null) {
          return 1; // Place undefined/null values at the bottom
        }
        if (b.joining_date === undefined || b.joining_date === null) {
          return -1; // Place undefined/null values at the bottom
        }
        return a.joining_date.localeCompare(b.joining_date);
      }
    },
    {
      title: "Training Period",
      dataIndex: "training_period",
      key: "training_period",
      sorter: (a: UserInterface, b: UserInterface) => {
        if (a.training_period === undefined || a.training_period === null) {
          return 1; // Place undefined/null values at the bottom
        }
        if (b.training_period === undefined || b.training_period === null) {
          return -1; // Place undefined/null values at the bottom
        }
        return a.training_period - b.training_period;
      }
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      render: (text) => {
        return text?.length>0 ? text : "-";
      },  
      sorter: (a: UserInterface, b: UserInterface) => {
        if (a.role === undefined || a.role === null) {
          return 1; // Place undefined/null values at the bottom
        }
        if (b.role === undefined || b.role === null) {
          return -1; // Place undefined/null values at the bottom
        }
        return a.role.localeCompare(b.role);
      }
    },
  ];

  useEffect(() => {
    dispatch(UserActions.getUserDetails({}));
  }, [dispatch]);

  const userDetails = useAppSelector(userSelectors.getUserDetails);
  const [allUsers, setUsers] = useState<UserInterface[]>([]);

  useEffect(() => {
    setUsers(userDetails);
  }, [userDetails]);

  const handleSearch = (searchedText: string) => {
    setSearchedTerm(searchedText);
    if (searchedText.length>0) {
      const filtered = userDetails.filter(user =>
        user.name.toLowerCase().includes(searchedText.toLowerCase())
      );
      setUsers(filtered);
    } else {
      setUsers(userDetails);
    }
  }

  return (
    <>
      <RegisterForm
      ></RegisterForm>
      <Card title="User Details">
        <div style={{ float: "left" }}>
          <Search
            value={searchedTerm}
            onChange={(e) => setSearchedTerm(e.target.value)}
            enterButton
            // loading={searchLoader} 
            placeholder="Search Employee" 
            onSearch={handleSearch} 
          />
        </div>

        <div style={{ float: "right" }}>
          <Button type="primary"
            title="Add New Employee"
            icon={<PlusOutlined />}
            onClick={() => {
              dispatch(leaveActions.leaveModelVisible({ visible: true }))
            }
            }
          >Add New Employee</Button>
        </div>

        <Table
          style={{ overflow: "auto" }}
          columns={columns}
          dataSource={allUsers}
          rowKey={(row) => row.id}
        />
      </Card>
    </>
  );
}

export default UserDetails;

import auth from "./auth/api";
import leave from './leaves/api';
import announcements from './announcements/api';
import history from './history/api'
import user from './user/api'

export interface LMSApi {
  auth: typeof auth;
  leave: typeof leave;
  announcements: typeof announcements;
  history : typeof history;
  user : typeof user;
}

const api: LMSApi = {
  auth,
  leave,
  announcements,
  history,
  user
};

export default api;

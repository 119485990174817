import { RootState } from "../store";


const historySelectors = {
    getDetails: (state: RootState) =>  state.history.histotyDetails,
    getAttendanceReport : (state: RootState) =>  state.history.attendanceReport,
    getError: (state: RootState) => state.history.error,
    uploadLogLoader: (state: RootState) => state.history.uploadLogLoader,
};

export default historySelectors;

import { Col, DatePicker, Modal, notification, Row, Select } from "antd";
import React, { FC, useState } from "react";
import { actions, historySelectors } from "../../../store/history";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { userSelectors } from "../../../store/users";
import * as FileSaver from "file-saver";
import Excel from "exceljs";
const { RangePicker } = DatePicker;

interface AttendanceReportProps {
  visible: boolean;
  onClose: () => void;
}

const ExportAttendanceForm: FC<AttendanceReportProps> = ({
  visible,
  onClose,
}) => {
  const dispatch = useAppDispatch();
  const attendanceData:any = useAppSelector(historySelectors.getAttendanceReport);
  const userDetails = useAppSelector(userSelectors.getUserDetails);
  const [userId, setuserId] = useState("-");

  function applyRowStyle(sheet: any) {
    sheet.eachRow(function (row: any, rowNumber: any) {
      if (rowNumber > 3) {
        row.eachCell(function (cell: any, colNumber: any) {
          sheet.getCell(cell.address.toString()).alignment = {
            wrapText: true,
            vertical: "middle",
            horizontal: "center",
          };
          sheet.getCell(cell.address.toString()).border = {
            top: { style: "thin" },
            right: { style: "thin" },
            bottom: { style: "thin" },
            left: { style: "thin" },
          };
          sheet.getCell(cell.address.toString()).font = {
            name: "arial",
            size: 10,
          };
        });
      }
    });
    return sheet;
  }

  var present: any = [];
  var halfday: any = [];
  var absent: any = [];

  function exportExcelAttendance() {
    var userKey = Object.keys(attendanceData!==undefined && attendanceData);

    let workbook = new Excel.Workbook();
    workbook.creator = "Web";
    workbook.lastModifiedBy = "Web";
    workbook.created = new Date();
    workbook.modified = new Date();
    let sheet_number = 0;
    var user_name = "";
    let columns = ["Date", "Check_in", "Check_out", "Work_time", "Status"];

    userKey.forEach((userId) => {
      sheet_number = sheet_number + 1;
      user_name =
        attendanceData!==undefined ? attendanceData[userId].user?.name : "";
      Object.entries(
        attendanceData!==undefined && attendanceData[userId].attendace_data
      ).forEach((val:any) => {
        if (
          val[1].status==="Present" ||
          val[1].status==="Saturday" ||
          val[1].status==="Casual Leave" ||
          val[1].status==="Adjustment Leave" ||
          val[1].status==="Sick Leave" ||
          val[1].status==="Earned Leave"
        ) {
          present.push({
            date: val[1].date,
            check_in: val[1].check_in,
            check_out: val[1].check_out,
            work_time: val[1].work_time?.h + "H" + val[1].work_time?.i + "M",
            status: val[1].status,
          });
        } else if (
          val[1].status==="Half-day" ||
          val[1].status==="Half-day (CO forgot)"
        ) {
          halfday.push({
            date: val[1].date,
            check_in: val[1].check_in,
            check_out: val[1].check_out,
            work_time: val[1].work_time?.h + "H" + val[1].work_time?.i + "M",
            status: val[1].status,
          });
        } else if (val[1].status==="Absent") {
          absent.push({
            date: val[1].date,
            check_in: val[1].check_in,
            check_out: val[1].check_out,
            work_time: val[1].work_time?.h + "H" + val[1].work_time?.i + "M",
            status: val[1].status,
          });
        }
      });
      workbook.addWorksheet(user_name, {
        views: [
          {
            state: "frozen",
            ySplit: 3,
            activeCell: "A1",
          },
        ],
      });
      let sheet = workbook.getWorksheet(user_name);
      sheet.addRow([user_name]);
      sheet.addRow("");
      sheet.mergeCells(
        `A  ${sheet.lastRow!.number - 1} : E ${sheet.lastRow!.number}`
      );
      sheet.addRow("");
      sheet.columns = [
        { key: "date", width: 20 },
        { key: "check_in", width: 25 },
        { key: "check_out", width: 25 },
        { key: "work_time", width: 25 },
        { key: "status", width: 15 },
      ];
      sheet.addRow("");
      sheet.addRow(["Present"]);
      sheet.addRow("");
      sheet.mergeCells(
        `A  ${sheet.lastRow!.number - 1} : E ${sheet.lastRow!.number}`
      );
      sheet.addRow("");
      sheet.addRow("");
      sheet.lastRow!.values = columns;

      present.forEach((val: any) => {
        sheet.addRow([
          val.date,
          val.check_in,
          val.check_out,
          val.work_time,
          val.status,
        ]);
      });

      sheet.addRow("");
      sheet.addRow(["Half day"]);
      sheet.addRow("");
      sheet.mergeCells(
        `A  ${sheet.lastRow!.number - 1} : E ${sheet.lastRow!.number}`
      );
      sheet.addRow("");
      sheet.lastRow!.values = columns;

      halfday.forEach((val: any) => {
        sheet.addRow([
          val.date,
          val.check_in,
          val.check_out,
          val.work_time,
          val.status,
        ]);
      });

      // absent
      sheet.addRow("");
      sheet.addRow(["Absent"]);
      sheet.addRow("");
      sheet.mergeCells(
        `A  ${sheet.lastRow!.number - 1} : E ${sheet.lastRow!.number}`
      );
      sheet.addRow("");
      sheet.lastRow!.values = columns;

      absent.forEach((val: any) => {
        sheet.addRow([
          val.date,
          val.check_in,
          val.check_out,
          val.work_time,
          val.status,
        ]);
      });

      sheet.addRow("");

      sheet.getCell("A1").alignment = {
        wrapText: true,
        vertical: "middle",
        horizontal: "center",
      };
      sheet.getCell("A1").font = {
        name: "arial",
        size: 14,
      };

      // sheet = applyHeaderStyle(headers, sheet);
      sheet = applyRowStyle(sheet);
      present = [];
      halfday = [];
      absent = [];
    });

    const blobType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: blobType });
      let name =
        (sheet_number===1 ? user_name + "-" : "All-") +
        new Date().toString() +
        ".xlsx";
      FileSaver.saveAs(blob, name, true);
    });
  }

  return (
    <Modal
      title="Download Attendance Report"
      visible={visible}
      onOk={() => {
        exportExcelAttendance();
        notification.success({ message: "Download success !!" });
      }}
      okText="Download"
      onCancel={onClose}
    >
      <Row gutter={[12, 12]}>
        <Col span={6}>User : </Col>
        <Col span={18}>
          <Select
            showSearch
            size={"middle"}
            defaultValue="All"
            onChange={(val) => setuserId(val)}
            style={{ width: 200 }}
            filterOption={(input, option) =>
              (option!.children as unknown as string)
                .toLowerCase()
                .includes(input.toLowerCase())
            }
          >
            <Select.Option key={"0"} value={"0"}>
              {"All"}
            </Select.Option>
            {userDetails?.map((user) => (
              <Select.Option key={user.id} value={user.id}>
                {user.name}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col span={6}>Duration : </Col>
        <Col span={18}>
          <RangePicker
            onChange={(val:any) => {
              dispatch(
                actions.attendanceReport({
                  user_id: userId,
                  start: val?.[0]?.format("YYYY-MM-DD") as string,
                  end: val?.[1]?.format("YYYY-MM-DD") as string,
                })
              );
            }}
          />
        </Col>
      </Row>
    </Modal>
  );
};

export default ExportAttendanceForm;

import React, { FC, useEffect } from "react";
import {
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  notification,
  Radio,
  Row,
  Select,
} from "antd";
import {
  LockOutlined,
  MailOutlined,
  PhoneOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { authActions } from "../../store/auth";
import { UserActions, userSelectors } from "../../store/users";
import moment from "moment";
import leaveSelectors from "../../store/leaves/selectors";
import { leaveActions } from "../../store/leaves";

interface RegisterFormValues {
  name: string;
  email: string;
  password: string;
  confirm_password: string;
  address: string;
  gender: string;
  phoneNum: string;
  dob: string;
  training_period: number;
  employee_role: number;
}

const RegisterForm: FC = () => {
  const [registerForm] = Form.useForm();
  const dispatch = useAppDispatch();
  const Modalvisible = useAppSelector(leaveSelectors.leaveModalVisible);
  useEffect(() => {
    dispatch(UserActions.getUserRole({}));
  }, [dispatch]);

  const userRole = useAppSelector(userSelectors.getUserRoleSelector);

  const onFinish = (values: RegisterFormValues) => {
    if (values.password===values.confirm_password) {
      dispatch(
        authActions.register({
          name: values.name,
          email: values.email,
          address: values.address,
          phone_number: values.phoneNum,
          password: values.password,
          birth_date: values.dob,
          training_period: values.training_period,
          employee_role: values.employee_role,
          gender: values.gender,
        })
      );
      dispatch(leaveActions.leaveModelVisible({ visible: false }));
      dispatch(UserActions.getUserDetails({}));
      registerForm.resetFields();
    } else {
      return notification.open({
        message: "Password and confirm password must be same !!",
      });
    }
  };

  return (
    <Modal
      title="Add New Employee"
      visible={Modalvisible}
      okText="Register"
      onOk={registerForm.submit}
      onCancel={() =>{
        registerForm.resetFields();
        dispatch(leaveActions.leaveModelVisible({ visible: false }))
      }
      }
      width={600}
    >
      <div className={"form-wrapper"}>
        <div
          style={{
            height: "650px",
            overflow: "auto",
            padding: "0px 15px 0px 5px",
          }}
        >
          <Form
            form={registerForm}
            name="horizontal_register"
            layout={"vertical"}
            onFinish={onFinish}
            initialValues={{
              gender: "M",
            }}
          >
            <Row>
              <Col span={24}>
                <Form.Item
                  label={"Name"}
                  name="name"
                  rules={[
                    { required: true, message: "Please input your name!" },
                  ]}
                >
                  <Input
                    size="large"
                    prefix={<UserOutlined className="site-form-item-icon" />}
                    placeholder="Name"
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label={"Email"}
                  name="email"
                  rules={[
                    { required: true, message: "Please input your email id!" },
                    { type: "email", message: "Email is not valid" },
                  ]}
                >
                  <Input
                    size="large"
                    prefix={<MailOutlined className="site-form-item-icon" />}
                    placeholder="Email"
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label={"Address"}
                  name="address"
                  rules={[
                    { required: true, message: "Please input your address!" },
                  ]}
                >
                  <Input.TextArea
                    size="large"
                    placeholder="Address"
                    autoSize={{ minRows: 2, maxRows: 2 }}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label={"Gender"}
                  name="gender"
                >
                  <Radio.Group>
                    <Radio value={"M"}>Male</Radio>
                    <Radio value={"F"}>Female</Radio>
                    <Radio value={"O"}>Others</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label={"Phone Number"}
                  name="phoneNum"
                  rules={[
                    {
                      required: true,
                      message: "Please input your phone number!",
                    },
                  ]}
                >
                  <Input
                    type="number"
                    size="large"
                    prefix={<PhoneOutlined className="site-form-item-icon" />}
                    placeholder="Phone number"
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="Password"
                  name="password"
                  rules={[
                    { required: true, message: "Please input your password!" },
                  ]}
                >
                  <Input.Password
                    size="large"
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    placeholder="Password"
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label={"Confirm Password"}
                  name="confirm_password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your confirm password!",
                    },
                  ]}
                >
                  <Input.Password
                    size="large"
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    placeholder="Confirm Password"
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label={"Date of Birth"}
                  name="dob"
                  rules={[
                    {
                      required: true,
                      message: "Please input your date of birth!",
                    },
                  ]}
                >
                  <DatePicker
                    size="large"
                    placeholder="Date of Birth"
                    format={"DD MMM, YYYY"}
                    className="input w-100"
                    disabledDate={(d:any) => !d || d.isAfter(moment())}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="Training Period"
                  name={"training_period"}
                  rules={[
                    { required: true, message: "Please select any option" },
                  ]}
                >
                  <Select size={"large"}> 
                    <Select.Option value={1}> 1 Month</Select.Option>
                    <Select.Option value={2}> 2 Months</Select.Option>
                    <Select.Option value={3}> 3 Months</Select.Option>
                    <Select.Option value={4}> 4 Months</Select.Option>
                    <Select.Option value={5}> 5 Months</Select.Option>
                    <Select.Option value={6}> 6 Months</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="Employee Role"
                  name={"employee_role"}
                  rules={[
                    { required: true, message: "Please select any role" },
                  ]}
                >
                  <Select size={"large"}>
                    {userRole.map((role) => (
                      <Select.Option key={role.id} value={role.id}>
                        {" "}
                        {role.type}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </Modal>
  );
};

export default RegisterForm;

import {
  lmsApiService,
  renewAccessTokenHandler,
} from "../../plugins/axios";
import authAdaptor from "./adaptor";
// Type Imports
import {
  LoginPayload,
  AuthResources,
  RegisterPayload,
  AuthApiInterface,
  ChangePasswordPayload,
  GoogleLoginPayload,
  ForgotPasswordPayload,
} from "./types";

// >>>>>>> Auth API <<<<<<<<<<
const AUTH_ENDPOINTS: AuthResources = {
  // login user
  LOGIN: {
    URL: "/login",
    METHOD: "POST",
  },
  // log-out user
  LOGOUT: {
    URL: "/logout",
    METHOD: "GET",
  },
  // add new user (registration)
  REGISTER: {
    URL: "/register",
    METHOD: "POST",
  },
  // Refresh token
  REFRESH_TOKEN: {
    URL: "/admin/renew-access-token",
    METHOD: "POST",
  },
  // Forgot passsword on modal 
  FORGOT_PASSWORD: {
    URL: "/resetPassword",
    METHOD: "POST",
  },
  // change password 
  CHANGE_PASSWORD: {
    URL: "/reset-password",
    METHOD: "POST",
  }
};

const authApi: AuthApiInterface = {
  login: async (data: LoginPayload) => {
    const { response, error } = await lmsApiService({
      resource: AUTH_ENDPOINTS.LOGIN,
      headers: {
        "x-verify-request": false,
      },
      options: {
        data,
        auth: false,
      },
    });

    const authState = authAdaptor({ response, error });
    return authState;
  },
  
  

  // Note: Sign Up is not working as of now leaving a register placeholder
  // for future use
  register: async (data: RegisterPayload) => {
    const { response, error } = await lmsApiService({
      resource: AUTH_ENDPOINTS.REGISTER,
      headers: {
        "x-verify-request": false,
      },
      options: {
        data,
        auth: false,
      },
    });
    if(error){
      return { error}; 
    }
    const authState = authAdaptor({ response, error });
    return authState;
  },

  logout: async () => {
    const { response, error } = await lmsApiService({
      resource: AUTH_ENDPOINTS.LOGOUT,
    });

    const authState = authAdaptor({ response, error });
    return authState;
  },

  refreshToken: async (token) => {
    const authToken = await renewAccessTokenHandler(token);

    if (!authToken) {
      return { authToken: null, error: null };
    }

    return { authToken, error: null };
  },
  forgotPassword: async (data: ForgotPasswordPayload) => {
    const { response, error } = await lmsApiService({
      resource: AUTH_ENDPOINTS.FORGOT_PASSWORD,
      headers: {
        "x-verify-request": false,
      },
      options: {
        data,
        auth: false,
      },
    });
    const authState = authAdaptor({ response, error });
    return authState;
  },
  changePassword: async (data: ChangePasswordPayload) => {
    const { response, error } = await lmsApiService({
      resource: AUTH_ENDPOINTS.CHANGE_PASSWORD,
      headers: {
        "x-verify-request": false,
      },
      options: {
        data,
        auth: false,
      },
    });

    const authState = authAdaptor({ response, error });

    return authState;
  },

  postGoogleLogin: async (data: GoogleLoginPayload) => {
    const { response, error } = await lmsApiService({
      resource: AUTH_ENDPOINTS.GOOGLE_LOGIN,
      options: {
        data,
        auth: false,
      },
    });

    const authState = authAdaptor({ response, error });

    return authState;
  },
  
};

export default authApi;

export { AUTH_ENDPOINTS };

import {call, getContext, put, takeLeading} from "redux-saga/effects";
import {actionTypes} from "./actions";
import {PayloadAction} from "@reduxjs/toolkit";
import {LMSApi} from "../../services/api";
import { Announcementactions } from './actions';
import { CreateAnnouncementPayload, GetAnnouncementsPayload } from "../../services/api/announcements/types";
import { AnnouncementState } from "./reducer";
import { notification } from "antd";

const {
  getAnnouncementsSuccess,
  getAnnouncementsFailure,
  celebrationDetailsSuccess,
  celebrationDetailsFailure,
  getAnnouncementTypesFailure,
  getAnnouncementTypesSuccess,
  createAnnouncementSuccess,
  createAnnouncementFailure
} = Announcementactions;

function* getAnnouncementsFlow(action: PayloadAction<GetAnnouncementsPayload>) {
  const api: LMSApi = yield getContext('api');
  const { announcements, error } = yield call(api.announcements.getAnnouncements, action.payload);

  if (error) {
    yield put(getAnnouncementsFailure({ announcements: null, error }));
  } else {
    yield put(getAnnouncementsSuccess({ announcements, error: null }))
  }

}
function* celebrationFlow(action: PayloadAction<GetAnnouncementsPayload>) {
  const api: LMSApi = yield getContext('api');
  const { celebrations, error } = yield call(api.announcements.celebration, action.payload);

  if (error) {
    yield put(celebrationDetailsFailure({ celebrations: null, error }));
  } else {
    yield put(celebrationDetailsSuccess({ celebrations, error: null }));
  }

}
function* announcementTypesFlow(action: PayloadAction<GetAnnouncementsPayload>) {
  const api: LMSApi = yield getContext('api');
  const { annoucementTypes, error } = yield call(api.announcements.getAnnoucementTypes, action.payload);

  if (error) {
    yield put(getAnnouncementTypesFailure({ annoucementTypes: null, error }));
  } else {
    yield put(getAnnouncementTypesSuccess({ annoucementTypes, error: null }));
  }

}
function* createAnnouncementFlow(action: PayloadAction<CreateAnnouncementPayload>) {
  const api: LMSApi = yield getContext('api');
  const { createAnnouncement, error } = yield call(api.announcements.createAnnouncement, action.payload);

  if (error) {
    yield put(createAnnouncementFailure({ createAnnouncement: null, error }));
  } else {
    yield put(createAnnouncementSuccess({ createAnnouncement, error: null }));
  }
}


export default function* announcementSaga() {
  yield takeLeading(actionTypes.GetAnnouncements, getAnnouncementsFlow);
  yield takeLeading(actionTypes.CelebrationDetails, celebrationFlow);
  yield takeLeading(actionTypes.GetAnnouncementTypes, announcementTypesFlow);
  yield takeLeading(actionTypes.CreateAnnouncementSuccess, function* (action: PayloadAction<AnnouncementState>) {
    yield notification.success({message: "Successful Announcement added" || "Something went wrong"})
  });
  yield takeLeading(actionTypes.CreateAnnouncement, createAnnouncementFlow);
}
import { authActions, authSelectors } from "../../store/auth";
import {
  Col,
  Form,
  Input,
  Row,
  Typography,
  notification,
  Card,
  Button,
} from "antd";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { useNavigate } from "react-router";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

interface ChangePasswordValues {
  email: string;
  password: string;
  confirm_password: string;
}

function ChangePasswordForm() {
  const [changePasswordForm] = Form.useForm<ChangePasswordValues>();
  const loader = useAppSelector(authSelectors.getIsFetching);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if(!searchParams.get('token')){
      navigate('/login')
    }
  }, [navigate,searchParams])
  
  const onFinish = (values: ChangePasswordValues) => {
    if (values.password===values.confirm_password) {
      dispatch(
        authActions.changePassword({
          mail: values.email,
          new: values.password,
          confirm: values.confirm_password,
        })
      );
    } else {
      notification.error({
        message:
          "Password & Confirm Password must be same !!" ||
          "Something went wrong",
      });
    }
  };

  return (
    <Card
      title="Change Password"
      bordered={true}
      style={{
        width: "500px",
        padding: "10px",
        margin: "auto",
      }}
    >
      <Form form={changePasswordForm} layout={"vertical"} onFinish={onFinish}>
        <Row>
          <Col span={8}>
            <Typography.Text>Email Id</Typography.Text>
          </Col>
          <Col span={16}>
            <Form.Item name="email" rules={[{ required: true }]}>
              <Input
                type="email"
                size="large"
                placeholder="Email"
                className="input"
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Typography.Text>New Password</Typography.Text>
          </Col>
          <Col span={16}>
            <Form.Item name="password" rules={[{ required: true }]}>
              <Input.Password
                type="password"
                size="large"
                placeholder="Password"
                className="input"
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Typography.Text>Confirm Password</Typography.Text>
          </Col>
          <Col span={16}>
            <Form.Item name="confirm_password" rules={[{ required: true }]}>
              <Input.Password
                type="password"
                size="large"
                placeholder="Confirm Password"
                className="input"
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Button
              type="primary"
              block
              size="large"
              loading={loader}
              onSubmit={changePasswordForm.submit}
              htmlType="submit"
            >
              Submit
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
  );
}

export default ChangePasswordForm;

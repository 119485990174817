import React from "react";
import { Modal } from "antd";

interface ViewModalInterface {
  handleClose: any;
  image: string;
  flag: string;
}

const ViewDocModal = ({ image, handleClose, flag }: ViewModalInterface) => {
  const { data }: any = image;

  return (
    <Modal
      title={"View Document"}
      visible={true}
      footer={null}
      onCancel={handleClose}
    >
      <img
        style={{ width: "100%" }}
        alt={flag}
        src={flag === "base64-img" ? `data:image/png;base64,${data}` : image}
      />
    </Modal>
  );
};

export default ViewDocModal;

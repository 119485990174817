import { useEffect } from "react";
import { Calendar, Tag, Space } from "antd";
import "./calender.less";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { actions, historySelectors } from "../../store/history";
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  FieldTimeOutlined,
} from "@ant-design/icons";
import moment from "moment";

const CalenderForm = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    const startOfMonth = moment()
          .clone()
          .startOf("month")
          .format("YYYY-MM-DD");
        const endOfMonth = moment().clone().endOf("month").format("YYYY-MM-DD");
    dispatch(
      actions.getHistory({
        startDate: startOfMonth,
        endDate: endOfMonth,
      })
    );
  }, [dispatch])
  const history = useAppSelector(historySelectors.getDetails);

  const userKey = Object.keys(history !== null && history)[0];
  

  const getListData = (value: any) => {
    let listData: any = [];

    Object.entries(
      history !== null && history[userKey].attendace_data!
    )?.forEach((val) => {
      if (value.format("YYYY-MM-DD") === val[1].date) {
        listData = [
          {
            key: val[0],
            status: val[1].status,
            checkin: val[1].check_in + " to " + val[1].check_out
          },
        ];
      }
    });
    return listData || [];
  };

  const dateCellRender = (value: any) => {
    const listData = getListData(value);
    return (
      <>
        <ul className="events">
          {listData?.map((item: any) => (
            <li key={item.key}>
              {item.status === "Present" ||
              item.status === "Adjustment Leave" ||
              item.status === "Casual Leave" ||
              item.status === "Sick Leave" ||
              item.status === "Earned Leave" ? (
                <Space direction="vertical">
                  <Tag icon={<CheckCircleOutlined />} color="success">
                    {item.status}
                  </Tag>
                  <Tag icon={<ClockCircleOutlined />} color="default">
                    {item.checkin}
                  </Tag>
                </Space>
              ) : item.status === "Half-day" ||
                item.status === "Half-day (CO forgot)" ? (
                <Space direction="vertical">
                  <Tag icon={<FieldTimeOutlined />} color="warning">
                    {item.status}
                  </Tag>

                  <Tag icon={<ClockCircleOutlined />} color="default">
                    {item.checkin}
                  </Tag>
                </Space>
              ) : item.status === "Saturday" ? (
                <Tag icon={<FieldTimeOutlined />} color="success">
                  Present
                </Tag>
              ) : (
                item.status === "Absent" && (
                  <>
                    <Tag
                      icon={<CloseCircleOutlined />}
                      color="error"
                    >
                      {item.status}
                    </Tag>
                    
                  </>
                )
              )}
            </li>
          ))}
        </ul>
      </>
    );
  };

  return (
    <Calendar
      style={{minWidth:"1000px"}}
      dateCellRender={dateCellRender}
      onPanelChange={(value) => {
        const startOfMonth = value
          .clone()
          .startOf("month")
          .format("YYYY-MM-DD");
        const endOfMonth = value.clone().endOf("month").format("YYYY-MM-DD");
        dispatch(
          actions.getHistory({
            startDate: startOfMonth,
            endDate: endOfMonth,
          })
        );
      }}
    />
  );
};

export default CalenderForm;

import React, { useEffect } from "react";
import {Card} from "antd";
import LeavesList from "../Leaves/leavesList";
import { useAppDispatch } from "../../store/hooks";
import { leaveActions } from "../../store/leaves/actions";

const LeaveRequestCard = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(leaveActions.getLeaverequests({}));
  }, [dispatch]);
  return (
    <Card title="Leave Requests" className={'h-100'} bodyStyle={{paddingTop: 12,overflow:"auto"}}>
      <LeavesList/>
    </Card>
  )
}

export default LeaveRequestCard;
import React, { useState } from "react";
import { Modal, Upload, notification } from "antd";
import { useAppDispatch } from "../../store/hooks";
import { leaveActions } from "../../store/leaves";
import { RcFile, UploadFile, UploadProps } from "antd/lib/upload/interface";
import { PlusOutlined } from "@ant-design/icons";
import ViewDocModal from "./viewDocModal";

interface UploadModalInterface {
  handleClose: any;
  leave_id: number;
}
const UploadModal = ({ handleClose, leave_id }: UploadModalInterface) => {
  const dispatch = useAppDispatch();
  const [file, setfile] = useState<UploadFile[]>([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");

  const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });
  const validateFile = (file: any) => {
    const isPNG =
      file.type === "image/jpg" ||
      file.type === "image/jpeg" ||
      file.type === "image/png";
    if (!isPNG) {
      notification.error({
        message: "Document type must be jpg/jpeg/png only !!",
      });
    }
    return isPNG || Upload.LIST_IGNORE;
  };
  const handleChange: UploadProps["onChange"] = ({ fileList: newFileList }) => {
    setfile(newFileList);
  };
  const closeModal = () => {
    setPreviewOpen(false);
  };

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }
    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
  };
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  return (
    <Modal
      title={"Upload Document"}
      visible={true}
      onOk={() => {
        if (file.length >= 1) {
          dispatch(
            leaveActions.uploadDoc({
              leave_id: leave_id,
              file: file[0],
            })
          );
          dispatch(leaveActions.getYourLeaves({}));
          handleClose();
        } else {
          notification.error({ message: "Please Attach Document" });
        }
      }}
      okText="Upload"
      onCancel={handleClose}
    >
      <div style={{ textAlign: "center" }}>
        <Upload
          style={{ height: "5000px !important" }}
          accept=".png,.jpg,.jpeg"
          listType="picture-card"
          beforeUpload={(file) => validateFile(file)}
          onChange={handleChange}
          onPreview={handlePreview}
          fileList={file}
          maxCount={1}
        >
          {file.length >= 1 ? null : uploadButton}
        </Upload>
        {previewOpen ? (
          <ViewDocModal
            image={previewImage}
            handleClose={closeModal}
            flag="img"
          />
        ) : null}
      </div>
    </Modal>
  );
};

export default UploadModal;

import { createAction } from "@reduxjs/toolkit";
import {GetUserDetailsPayload, UserDetailsUpdateDeletePayload, UserDetailsUpdatePayload, UserResponse, UserRolePayload} from "../../services/api/user/types";

// >>>>>>> Leave ActionTypes <<<<<<<<<
export const actionTypes = {
  GetCurrentUserDetails: "[User] Get Current User Details",
  GetCurrentUserDetailsSuccess: "[User] Get Current User Details Success",
  GetCurrentUserDetailsFailure: "[User] Get Current User Details Failure",

  GetUserDetails: "[User] Get User Details",
  GetUserDetailsSuccess: "[User] Get User Details Success",
  GetUserDetailsFailure: "[User] Get User Details Failure",

  UpdateUserDetails: "[User] Update User Details",
  UpdateUserDetailsSuccess: "[User] Update User Details Success",
  UpdateUserDetailsFailure: "[User] Update User Details Failure",

  DeleteUserDetails: "[User] Delete User Details",
  DeleteUserDetailsSuccess: "[User] Delete User Details Success",
  DeleteUserDetailsFailure: "[User] Delete User Details Failure",

  UserModelId:"[User] User model id",

  GetUserRole: "[User] Get User Role",
  GetUserRoleSuccess: "[User] Get User Role Success",
  GetUserRoleFailure: "[User] Get User Role Failure",

  GetUserStatistics: "[User] Get User Statistics",
  GetUserStatisticsSuccess: "[User] Get User Statistics Success",
  GetUserStatisticsFailure: "[User] Get User Statistics Failure",

};

// >>>>>>> Action Payload Interfaces <<<<<<<<<

// >>>>>>> Leave Actions <<<<<<<<<
export const UserActions = {
  getCurrentUserDetails: createAction(actionTypes.GetCurrentUserDetails),
  getCurrentUserDetailsSuccess: createAction<UserResponse>(actionTypes.GetCurrentUserDetailsSuccess),
  getCurrentUserDetailsFailure: createAction<UserResponse>(actionTypes.GetCurrentUserDetailsFailure),

  getUserDetails: createAction<GetUserDetailsPayload>(actionTypes.GetUserDetails),
  getUserDetailsSuccess: createAction<UserResponse>(actionTypes.GetUserDetailsSuccess),
  getUserDetailsFailure: createAction<UserResponse>(actionTypes.GetUserDetailsFailure),

  updateUserDetails: createAction<UserDetailsUpdatePayload>(actionTypes.UpdateUserDetails),
  updateUserDetailsSuccess: createAction<UserResponse>(actionTypes.UpdateUserDetailsSuccess),
  updateUserDetailsFailure: createAction<UserResponse>(actionTypes.UpdateUserDetailsFailure),

  deleteUserDetails: createAction<UserDetailsUpdateDeletePayload>(actionTypes.DeleteUserDetails),
  deleteUserDetailsSuccess: createAction<UserResponse>(actionTypes.DeleteUserDetailsSuccess),
  deleteUserDetailsFailure: createAction<UserResponse>(actionTypes.DeleteUserDetailsFailure),

  UserModelId: createAction<UserDetailsUpdateDeletePayload>(actionTypes.UserModelId),

  getUserRole: createAction<UserRolePayload>(actionTypes.GetUserRole),
  getUserRoleSuccess: createAction<UserResponse>(actionTypes.GetUserRoleSuccess),
  getUserRoleFailure: createAction<UserResponse>(actionTypes.GetUserRoleFailure),

  getUserStatistics: createAction<UserRolePayload>(actionTypes.GetUserStatistics),
  getUserStatisticsSuccess: createAction<UserResponse>(actionTypes.GetUserStatisticsSuccess),
  getUserStatisticsFailure: createAction<UserResponse>(actionTypes.GetUserStatisticsFailure),
};

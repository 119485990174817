import { createAction } from "@reduxjs/toolkit";
import {AnnouncementResponse, CelebrationPayload, CreateAnnouncementPayload, GetAnnouncementsPayload} from "../../services/api/announcements/types";

// >>>>>>> Announcement ActionTypes <<<<<<<<<
export const actionTypes = {
  GetAnnouncements: "[Annoucment] Get Annoucments",
  GetAnnouncementsSuccess: "[Annoucment] Get Annoucments Success",
  GetAnnouncementsFailure: "[Annoucment] Get Annoucments Failure",

  GetAnnouncementTypes: "[Annoucment] Get Annoucment Types",
  GetAnnouncementTypesSuccess: "[Annoucment] Get Annoucment Types Success",
  GetAnnouncementTypesFailure: "[Annoucment] Get Annoucment Types Failure",

  CreateAnnouncement: "[Annoucment] Create Announcement",
  CreateAnnouncementSuccess: "[Annoucment] Create Announcement Success",
  CreateAnnouncementFailure: "[Annoucment] Create Announcement Failure",

  CelebrationDetails: "[Annoucment] Celebration Details",
  CelebrationDetailsSuccess: "[Annoucment] Celebration Details Success",
  CelebrationDetailsFailure: "[Annoucment] Celebration Details Failure",
};

// >>>>>>> Action Payload Interfaces <<<<<<<<<

// >>>>>>> Leave Actions <<<<<<<<<
export const Announcementactions = {
  getAnnouncements: createAction<GetAnnouncementsPayload>(actionTypes.GetAnnouncements),
  getAnnouncementsSuccess: createAction<AnnouncementResponse>(actionTypes.GetAnnouncementsSuccess),
  getAnnouncementsFailure: createAction<AnnouncementResponse>(actionTypes.GetAnnouncementsFailure),

  getAnnouncementTypes: createAction<GetAnnouncementsPayload>(actionTypes.GetAnnouncementTypes),
  getAnnouncementTypesSuccess: createAction<AnnouncementResponse>(actionTypes.GetAnnouncementTypesSuccess),
  getAnnouncementTypesFailure: createAction<AnnouncementResponse>(actionTypes.GetAnnouncementTypesFailure),

  createAnnouncement: createAction<CreateAnnouncementPayload>(actionTypes.CreateAnnouncement),
  createAnnouncementSuccess: createAction<AnnouncementResponse>(actionTypes.CreateAnnouncementSuccess),
  createAnnouncementFailure: createAction<AnnouncementResponse>(actionTypes.CreateAnnouncementFailure),

  celebrationDetails: createAction<CelebrationPayload>(actionTypes.CelebrationDetails),
  celebrationDetailsSuccess: createAction<AnnouncementResponse>(actionTypes.CelebrationDetailsSuccess),
  celebrationDetailsFailure: createAction<AnnouncementResponse>(actionTypes.CelebrationDetailsFailure),
};

import React from "react";
import {Button, Empty, Space, Table, TableColumnsType} from "antd";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {leaveActions, leaveSelectors} from "../../store/leaves";
import {LeaveInterface} from "../../services/api/leaves/types";
import moment from "moment";
import {CheckCircleFilled, CloseCircleFilled} from "@ant-design/icons";

const LeavesList = () => {

  const leaves = useAppSelector(leaveSelectors.getLeavesList);
  const leaveLoader = useAppSelector(leaveSelectors.getLeaveLoader);
  const leaveReqId = useAppSelector(leaveSelectors.SelectLeaveRequestId);
  
  const dispatch = useAppDispatch();

// Sample Columns data
  const columns: TableColumnsType<LeaveInterface> = [
    {
      key: 1,
      title: <b style={{ color: "#F4326C"}}>User</b>,
       render: (_,leaves) => <>{leaves.user.name}</>
    },
    {
      key: 2,
      title: <b style={{ color: "#F4326C"}}>Duration</b>,
      render: (_, leave) => {
        return (
          <>{moment(leave.start_date).format('DD MMM YYYY')} - {moment(leave.end_date).format('DD MMM YYYY')}</>
        )
      }
    },
    {
      key: 3,
      title: <b style={{ color: "#F4326C"}}>Type</b>,
      render: (_,leave) => <>{leave.request_type.type} {leave.is_emergency===1 && "( Emergency )"} {leave.is_alternate_shift===1 && "( Alternate shift )"}</>
    },
    {
      key: 4,
      title: <b style={{ color: "#F4326C"}}>Reason</b>,
      dataIndex: 'reason',
      width: 200,
    },
    {
      key: 5,
      title:<b style={{ color: "#F4326C"}}>Half leave</b>,
      render: (_, leave) => (
        <>{leave.is_half_leave===0 ? "-" : "Half leave"}</>
      ),
    },
    {
      key: 6,
      title: <b style={{ color: "#F4326C"}}>Adjustment Date</b>,
      render: (_, leave) => <>{leave.adjustment_date??"-"}</>,
    },
    {
      key: 7,
      title:<b style={{ color: "#F4326C"}}>Half Leave Time /<br/> Alternate Shift Time</b>,
      render: (_, leave) => <>{leave.half_leave_time??"-"}</>,
    },
    {
      key: 8,
      title: <b style={{ color: "#F4326C"}}>Days</b>,
      dataIndex:"days"
    },
   
    {
      key: 9,
      title: <b style={{ color: "#F4326C"}}>Action</b>,
      // className: 'd-flex justify-content-center',
      render: (_, leave) => {
        return (
          <Space>
            <Button
              className={'ant-btn-success'}
              size={'small'}
              icon={<CheckCircleFilled/>}
              title="Approve"
              loading={leaveReqId[0]?.id===leave.id && leaveReqId[0]?.status==="Approve" &&leaveLoader}
              onClick = {
                ()=>{
                  dispatch(leaveActions.selectLeaveRequestId({
                    id:leave.id,
                    status:"Approve"
                  }))
                  dispatch(leaveActions.leaveAction({
                    req_id:leave.id,
                    status_id:leave.status_id+1
                  }
                ))
                dispatch(leaveActions.getLeaverequests({}));
              }
              }
            >Approve</Button>
            <Button
              className={'ant-btn-danger'}
              size={'small'}
              icon={<CloseCircleFilled/>}
              title="Decline"
              loading={leaveReqId[0]?.id===leave.id && leaveReqId[0]?.status==="Decline" &&leaveLoader}
              onClick = {
                ()=>{
                  dispatch(leaveActions.selectLeaveRequestId({
                    id:leave.id,
                    status:"Decline"
                  }))
                  dispatch(leaveActions.leaveAction({
                  req_id:leave.id,
                  status_id:leave.status_id+2
                }))
              }
              }
            >Decline</Button>
          </Space>
        )
      }
    },
  ];
  
  return (
    <Table<LeaveInterface>
      dataSource={leaves}
      columns={columns}
      pagination={false}
      rowKey={(row)=>row.id}
      locale={{emptyText:<Empty style={{color:"black"}} description="No Leave request !!"></Empty>}}
      className='text-center'
      
    />
  )
}

export default LeavesList;
import React, {FC, useState} from 'react';
import {Button, Layout} from "antd";
import './style.less';
import Sidebar from "./Sidebar";
import fxLogo from "../../assets/fx-logo.png";
import { Navigate, Route, Routes } from "react-router-dom";
import Dashboard from "../Dashboard";
import History from "../History";
import Navbar from "../Navbar";
import Report from "../Report";
import { MenuFoldOutlined } from "@ant-design/icons";
import LeaveRequestCard from "../Dashboard/LeaveRequestCard";
import UserDetails from '../Users/UserDetails';
import { MenuUnfoldOutlined} from '@ant-design/icons';
import { useAppDispatch, useAppSelector} from "../../store/hooks";
import {uiSelectors} from "../../store/ui";
import {toggleSidebarCollapse} from "../../store/ui/reducer";
import { useNavigate } from 'react-router-dom';
import { authSelectors } from '../../store/auth';

const LayoutComponent:FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { Header, Content, Sider } = Layout;
  const sideBarCollapsed = useAppSelector(uiSelectors.getSidebarCollapsed);
  const [sidebarVisible, setSidebarVisible] = useState(false)
  const getUser = useAppSelector(authSelectors.getUser);
  const onCollapse = (collapsed: boolean) => {
    dispatch(toggleSidebarCollapse(collapsed));
  }
  const mobileCollapse = ()=>{
    if(/Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)===true){
      return true;
    }else {
      return false;
    }
  }

  const ProtectedRoute = ({children}:any)=>{
    if(getUser?.scope!=="ADMIN"){
      console.log(getUser);
      return <Navigate to="/dashboard" />;
    }
    return children;
  }
  return (
    <Layout className={"layout-wrapper"} hasSider>
      <Sider theme={'light'} collapsed={mobileCollapse() || sideBarCollapsed} className={sidebarVisible?'sidebar':'hide-mobile-sidebar'}>
        <div className={"sidebar-logo"}>
          <img src={fxLogo} alt={"logo"} onClick={()=>navigate('/')} />
        </div>
        {!mobileCollapse() && <div className={'sidebar-collapse-btn'}>
          <Button
            type='primary'
            onClick={() => onCollapse(!sideBarCollapsed)}
            icon={sideBarCollapsed ? <MenuUnfoldOutlined/> : <MenuFoldOutlined/>}
          />
        </div>}
        <Sidebar/>
      </Sider>
      <Layout className={'content-wrapper'}>
        <Header className={'main-header'}>
          <Navbar setSidebarVisible = {setSidebarVisible}/>

        </Header>
        <Content className={"main-content"}>
          <Routes>
            <Route path={"dashboard"} element={<Dashboard />} />
            <Route path={"attendance"} element={<History />} />
            <Route path={"leave-request"} element={<ProtectedRoute><LeaveRequestCard /></ProtectedRoute>} />
            <Route path={"reports"} element={<ProtectedRoute><Report/></ProtectedRoute>} />
            <Route path={"user-details"} element={<ProtectedRoute><UserDetails/></ProtectedRoute>} />
            
            <Route path={"*"} element={<Navigate to={"dashboard"} />} />
          </Routes>
        </Content>
        {/*<Footer>Footer</Footer>*/}
      </Layout>
    </Layout>
  );
};

export default LayoutComponent;

/* eslint-disable eqeqeq */
import { DeleteOutlined, DownloadOutlined, EditOutlined, ExclamationCircleOutlined, EyeOutlined, UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Empty,
  Modal,
  Row,
  Select,
  Space,
  Table,
  TableColumnsType,
  Tabs,
  Typography,
} from "antd";
import { useEffect, useState } from "react";
import {
  LeaveInterface,
} from "../../services/api/leaves/types";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { leaveActions, leaveSelectors } from "../../store/leaves";
import userSelectors from "../../store/users/selectors";
import LeaveCard from "../UI/LeaveCard";
import ExportLeaveReport from "./ExportReport/ExportLeaveReport";
import ViewDocModal from "../Leaves/viewDocModal";
import axios from "axios";
import store from "../../store/store";
import UpdateLeaveRequestForm from "../Dashboard/Leaves/UpdateLeaveRequestForm";
import UploadModal from "../Leaves/uploadModal";
const { confirm } = Modal;
const { REACT_APP_ENV, REACT_APP_DEV_URL, REACT_APP_PROD_URL } = process.env;
const { Option } = Select;
const { TabPane } = Tabs;
const LeaveRequestSection = () => {
  const dispatch = useAppDispatch();
  const userDetails = useAppSelector(userSelectors.getUserDetails)?.filter(u => u.status === 1);

  const [userName, setuserName] = useState("All");
  const leaveReport = useAppSelector(leaveSelectors.getLeaveReport);
  const [modalVisible, setmodalVisible] = useState(false);
  const [viewModalVisible, setviewModalVisible] = useState(false);
  const [statusTab, setStatusTab] = useState("1");
  const [leaveId, setLeaveId] = useState(0);
  const [loader, setloader] = useState(false);
  const [doc, setDoc] = useState<any>();
  function statusCallBack(key: string) {
    setStatusTab(key);
  }

  const dataResource: any = [];
  leaveReport[0]?.leaves.map(
    (leave) =>
      leave.status_id == parseInt(statusTab) && dataResource.push(leave)
  );

  useEffect(() => {
    dispatch(
      leaveActions.leaveReport({
        users: "0",
      })
    );
  }, [dispatch]);

  function UserLeaves(val: string) {
    userDetails?.map((users) => {
      if (parseInt(val) === 0) {
        return setuserName("All")
      } else {
        return users.id == parseInt(val) && setuserName(users.name);
      }
    });
    dispatch(
      leaveActions.leaveReport({
        users: val,
      })
    );
  }
  const handleViewModalOpem = (id: number) => {
    setLeaveId(id);
    setloader(true);
    const url = REACT_APP_ENV === "development" ? REACT_APP_DEV_URL : REACT_APP_PROD_URL
    axios.get(url + '/get-s3-img/' + id,
      {
        headers: { 'Authorization': `Bearer ${store.getState().auth.authToken}` }
      }
    ).then((result) => {
      setviewModalVisible(true);
      setDoc((result));
      setloader(false);

    }).catch((err) => {
      console.log(err);
      setloader(false);
    });

  };
  const handleViewModalClose = () => {
    setviewModalVisible(false);
  };

  const handleOpen = (id: number) => {
    setLeaveId(id);
    setmodalVisible(true);
  };

  const handleClose = () => {
    setmodalVisible(false);
  };

  const DeleteRequestModal = (reqId: number) => {
    confirm({
      title: "Are you sure want to delete this request ??",
      icon: <ExclamationCircleOutlined />,

      onOk() {
        dispatch(
          leaveActions.deleteLeaveRequest({
            id: reqId,
          })
        );
        dispatch(leaveActions.getYourLeaves({}));
      },
    });
  };

  // approve column :
  const approve: TableColumnsType<LeaveInterface> = [
    {
      title: <b style={{ color: "#F4326C" }}>Action</b>,
      render: (_, leaves) => {
        return (
          leaves.status_id !== 3 && (
            <Space>
              <UpdateLeaveRequestForm
                reqId={leaves.id}
                start_date={leaves.start_date}
                end_date={leaves.end_date}
                reason={leaves.reason}
                status_id={leaves.status_id}
                typeId={leaves.request_type.id}
                type={leaves.request_type.type}
                days={leaves.days}
                adjustment_date={leaves.adjustment_date}
                half_leave_time={leaves.half_leave_time!}
                is_emergency={leaves.is_emergency}
                is_half_leave={leaves.is_half_leave}
                is_alternate_shift={leaves.is_alternate_shift}
              ></UpdateLeaveRequestForm>

              {leaves.request_type.id === 2 &&
                (!leaves.doc_url ? (
                  <>
                    <Button
                      style={{ color: "white", background: "#1890ff" }}
                      size={"middle"}
                      icon={<UploadOutlined />}
                      onClick={() => handleOpen(leaves.id)}
                      title="Upload Docs"
                    ></Button>
                  </>
                ) : (
                  <>
                    <Button
                      style={{ color: "white", background: "#f6be40" }}
                      size={"middle"}
                      loading={leaves.id === leaveId && loader}
                      icon={<EyeOutlined />}
                      onClick={() => handleViewModalOpem(leaves.id)}
                      title="View Document"
                    ></Button>
                  </>
                ))}
              {leaves.status_id === 2 && (
                <Button
                  className={"ant-btn-success"}
                  size={"middle"}
                  icon={<EditOutlined />}
                  title="Update"
                  onClick={() => {
                    dispatch(
                      leaveActions.selectedLeaveId({
                        id: leaves.id,
                      })
                    );
                    dispatch(
                      leaveActions.updateLeaveModelVisible({ visible: true })
                    );
                  }}
                ></Button>
              )}
              {leaves.status_id === 2 && (
                <Button
                  className={"ant-btn-danger"}
                  size={"middle"}
                  icon={<DeleteOutlined />}
                  title="Delete"
                  onClick={() => {
                    DeleteRequestModal(leaves.id);
                  }}
                ></Button>
              )}
            </Space>
          )
        );
      }
    },
    {
      key: 1,
      title: <b style={{ color: "#F4326C" }}>User</b>,
      render: (_, leaves) => <>{leaves?.user?.name}</>,
    },
    {
      key: 2,
      title: <b style={{ color: "#F4326C" }}>Duration</b>,
      // dataIndex: "start",
      render: (_, leaves) => (
        <>
          {leaves?.start_date ?? "-"} - {leaves?.end_date ?? "-"}
        </>
      ),
    },
    {
      key: 3,
      title: <b style={{ color: "#F4326C" }}>Type</b>,

      render: (_, leaves) => (
        <>
          {leaves?.request_type?.type ?? "-"}
          {leaves.is_emergency == 1 && " ( Emergency )"}{" "}
          {leaves.is_alternate_shift == 1 && " ( Alternate Shift )"}

        </>
      ),
    },
    {
      key: 4,
      title: <b style={{ color: "#F4326C" }}>Reason</b>,
      width: 200,

      render: (_, leaves) => <>{leaves?.reason ?? "-"}</>,
    },
    {
      key: 5,
      title: <b style={{ color: "#F4326C" }}>Half leave</b>,
      render: (_, leaves) => (
        <>{leaves?.is_half_leave == 1 ? "Half leave" : "-"}</>
      ),
    },
    {
      key: 6,
      title: <b style={{ color: "#F4326C" }}>Adjustment Date</b>,
      render: (_, leaves) => <>{leaves?.adjustment_date ?? "-"}</>,
    },
    {
      key: 7,
      title: (
        <b style={{ color: "#F4326C" }}>
          Half Leave Time /<br /> Alternate Shift Time
        </b>
      ),
      render: (_, leaves) => <>{leaves?.half_leave_time ?? "-"}</>,
    },
    {
      key: 8,
      title: <b style={{ color: "#F4326C" }}>Days</b>,
      render: (_, leaves) => <>{leaves?.days ?? "-"}</>,
    },
  ];
  const [modelVisible, setmodelVisible] = useState(false);
  return (
    <div>
      <Row justify={"space-between"} gutter={[12, 12]}>
        <Col>
          <Select
            showSearch
            size={"middle"}
            defaultValue="All"
            onChange={UserLeaves}
            style={{ width: 200 }}
            filterOption={(input, option) =>
              (option!.children as unknown as string)
                .toLowerCase()
                .includes(input.toLowerCase())
            }
          >
            <Option key={`user_0`} value={0}>
              {"All"}
            </Option>

            {userDetails?.map((user) => (
              <Option key={`user_${user.id}`} value={user.id}>
                {user.name}
              </Option>
            ))}
          </Select>
        </Col>

        <Col>
          <Space>
            <Button
              type="primary"
              style={{ background: "#F4326C", border: "none" }}
              icon={<DownloadOutlined />}
              onClick={() => {
                setmodelVisible(true)
              }
              }
            >
              Download
            </Button>
            <ExportLeaveReport visible={modelVisible} onClose={() => setmodelVisible(false)} ></ExportLeaveReport>
          </Space>
        </Col>
        <Col span={24}>
          <div className={"leave-details-wrapper"}>
            {leaveReport?.map((leave) =>
              leave?.description?.map((desc) => (
                <LeaveCard
                  id={desc.id}
                  key={desc.id}
                  type={desc.type}
                  color={desc.color}
                  total={desc.total}
                  available={desc.available}
                  isUnlimited={desc.isUnlimited == 1 ? 1 : 0}
                ></LeaveCard>
              ))
            )}
          </div>
        </Col>
      </Row>
      <br />
      <br />
      <div
        style={{
          overflow: "auto",
          background: "#FAFAFA",
          padding: 18,
        }}
      >
        <Tabs onChange={statusCallBack}>
          <TabPane tab="PENDING" key="1"></TabPane>
          <TabPane tab="APPROVED" key="2"></TabPane>
          <TabPane tab="DECLINED" key="3"></TabPane>
        </Tabs>

        <Typography.Text>{userName}</Typography.Text>

        <Table
          dataSource={dataResource}
          columns={approve}
          rowKey={(row) => row.id}
          locale={{
            emptyText: (
              <Empty
                style={{ color: "black" }}
                description="No Leave request !!"
              ></Empty>
            ),
          }}
        />
        {modalVisible ? (
          <UploadModal handleClose={handleClose} leave_id={leaveId} />
        ) : null}
        {viewModalVisible ? (
          <ViewDocModal
            handleClose={handleViewModalClose}
            image={doc}
            flag="base64-img"
          />
        ) : null}
      </div>
    </div>
  );
};

export default LeaveRequestSection;

// TODO: Look for  a way to block axios import in React components
// TODO: Add husky, lint-staged to package.json

import axios from "axios";

// Local Imports
import store from "../../store/store";
import { authActions } from "../../store/auth";
import {
  resetAuthState,
  setAuthToken,
} from "../../store/auth/reducer";
import packageJson from "../../../package.json";

// Type Imports
import { LMSApiService, ProcessApiProps } from "../api/types";

// Constant Imports
import { API_EXCEPTIONS } from "../../constants";
import { AUTH_ENDPOINTS } from "../api/auth/api";
import log from "../../services/log";
import { parseJwt } from "../../utils/parseJWT";
import APIURL from "../../utils/getApiURL";

// Destructuring for local use
const { renewAccessToken } = authActions;

// >>>>>> Gold API Constants <<<<<<<<
const APP_VERSION = packageJson.version;
const APP_ENV = process.env.REACT_APP_ENV || "development";
const API_BASE = APIURL || "http://127.0.0.1:8000/api";

// >>>>>> Gold API Client <<<<<<<<<<
const lmsApi = axios.create({
  baseURL: API_BASE,
  headers: {
    "Content-Type": "application/json",
    "device-version": APP_VERSION, // Should be renamed to x-device-version in the future
    "device-env": APP_ENV, // Should be renamed to x-device-env in the future
    "device-type": "DASHBOARD", // Should be renamed to x-device-type in the future
  },
});

// >>>>>> Request Interceptor <<<<<<<<<<
lmsApi.interceptors.request.use(
  async (request) => {
    if (request) {
      request.headers = {
        ...request.headers,
      };

      // --------->> Use auth or not? <----------
      // We sent this header from request initializer based on
      // whether the request should be verified or not
      if ("x-verify-request" in request.headers) {
        const verifyRequest = request.headers["x-verify-request"];
        delete request.headers["x-verify-request"];

        if (!verifyRequest) {
          return request;
        }
      }

      // -----> Check if token is valid <-----
      const authToken = store.getState().auth.authToken;

      // Reset auth state & Reject if token is not present
      if (!authToken) {
        store.dispatch(resetAuthState());
        throw new Error(API_EXCEPTIONS.UNAUTHORIZED);
      }

      // Check if token is expired
      const parsedToken = parseJwt(authToken);

      if (parsedToken.exp <= Math.round(Date.now() / 1000)) {
        log("Token Expired, refreshing...");
        const refreshToken = store.getState().auth.refreshToken;
        // Renew token
        if (refreshToken) {
          const newAuthToken = await renewAccessTokenHandler(refreshToken);

          // Reject if new token is not received
          if (!newAuthToken) {
            throw new Error(API_EXCEPTIONS.UNAUTHORIZED);
          }
          // Update auth token in request object
          request.headers = {
            ...request.headers,
            Authorization: "Bearer " + newAuthToken,
            // 'x-access-token': newAuthToken,
          };
          return request;
        } else {
          store.dispatch(resetAuthState());
        }
      } else {
        request.headers["Authorization"] = "Bearer " + authToken;
        return request;
      }
      return request;
    }
  },
  (err) => Promise.reject(err)
);

// >>>>>> Response Interceptor <<<<<<<<<<
lmsApi.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response) {
      const { status, data } = error.response;

      // Handle FWC_TOKEN_EXCEPTION
      if ([401, 403].includes(status)) {
        store.dispatch(authActions.logout())
        if (data.error.code === API_EXCEPTIONS.FWC_TOKEN_EXCEPTION) {
          log("Refreshing token");
          const { refreshToken } = store.getState().auth;

          refreshToken
            ? store.dispatch(renewAccessToken(refreshToken))
            : store.dispatch(resetAuthState());
        }
        // Not FWC_TOKEN_EXCEPTION --> logout()
        store.dispatch(resetAuthState());
      }
    }
    return Promise.reject(error);
  }
);

// >>>>>> Gold Api Service <<<<<<<<<<
export const lmsApiService: LMSApiService = async ({
  resource,
  options,
  headers,
}) => {
  const { url, method, data } = processApiProps({ resource, options });

  // log(`[goldApi] 📤 : ${method} -> ${url}`, data);

  let error: any, response: any;

  try {
    response = await lmsApi.request({
      url,
      method,
      data,
      headers: {
        ...headers,
      },
    });

    // log("[goldApi] 📥  Success: ", returnObject);

    return {
      response: response?.data,
      error: null,
    };
  } catch (err) {
    error = err;

    // log("[goldApi] 📥  Error: ", err);

    if (error.response) {
      return {
        response: null,
        error: { message: error.response.data.error },
      };
    }
    return {
      response: null,
      error: {
        code: API_EXCEPTIONS.UNKNOWN_EXCEPTION,
        message: "Unknown Error",
      },
    };
  }
};

// >>>>>> Gold Api Helper Functions <<<<<<<<<<

const processApiProps: ProcessApiProps = ({ resource, options = {} }) => {
  let url = resource.URL;
  const method = resource.METHOD;

  // Replace path vars with their value if any
  const pathVars = options.pathVars;

  if (pathVars) {
    Object.keys(pathVars).forEach(
      (key) => (url = url.replace(`:${key}`, `${pathVars[key]}`))
    );
  }

  // Add queryParams if any
  const queryParams = options.queryParams;
  if (queryParams) {
    url +=
      "?" +
      Object.keys(queryParams)
        .map((key) => key + "=" + queryParams[key])
        .join("&");
  }

  return {
    url,
    method,
    data: options.data,
  };
};

interface RenewAccessTokenHandler {
  (token: string): Promise<string | null>;
}

export const renewAccessTokenHandler: RenewAccessTokenHandler = async (
  token
) => {
  if (token) {
    const { response, error } = await lmsApiService({
      resource: AUTH_ENDPOINTS.REFRESH_TOKEN,
      options: {
        queryParams: {
          refreshToken: token,
        },
        auth: false,
      },
    });

    if (response) {
      const accessToken = response.accessToken;
      localStorage.setItem("authToken", accessToken);
      store.dispatch(setAuthToken(accessToken));
      return accessToken;
    }

    if (error) {
      store.dispatch(resetAuthState());
      return null;
    }
  }

  return null;
};

export default lmsApi;

import { RootState } from "../store";

const authSelectors = {
  getAuthToken: (state: RootState) => state.auth.authToken,
  getRefreshToken: (state: RootState) => state.auth.refreshToken,
  getIsAuthenticated: (state: RootState) => state.auth.isAuthenticated,
  getUser: (state: RootState) => state.auth.user,
  getIsFetching: (state: RootState) => state.auth.isFetching,
  getAuthError: (state: RootState) => state.auth.error,
};

export default authSelectors;

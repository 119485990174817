import { useState } from "react";
import {
  Button,
  Space,
  Table,
  TableColumnsType,
  Tag,
  Modal,
  Empty,
} from "antd";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { leaveActions, leaveSelectors } from "../../store/leaves";
import { YourLeaveInterface } from "../../services/api/leaves/types";
import emptyLeaveRequest from "../../assets/icons/no_leave_request.svg";
import moment from "moment";
import {
  CheckCircleFilled,
  ClockCircleFilled,
  CloseCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import UploadModal from "./uploadModal";
import ViewDocModal from "./viewDocModal";
import UpdateLeaveRequestForm from "../Dashboard/Leaves/UpdateLeaveRequestForm";
import axios from "axios";
import store from "../../store/store";
const { confirm } = Modal;
const { REACT_APP_ENV, REACT_APP_DEV_URL, REACT_APP_PROD_URL } = process.env;
const YourLeaveList = () => {
  const dispatch = useAppDispatch();
  const yourLeaves = useAppSelector(leaveSelectors.getYourLeaveList);
  const [modalVisible, setmodalVisible] = useState(false);
  const [viewModalVisible, setviewModalVisible] = useState(false);
  const [leaveId, setLeaveId] = useState(0);
  const [loader, setloader] = useState(false);
  const [doc, setdoc] = useState<any>();

  const handleOpen = (id: number) => {
    setLeaveId(id);
    setmodalVisible(true);
  };
  const handleClose = () => {
    setmodalVisible(false);
  };
  const handleViewModalOpem = (id: number) => {
    const url =
      REACT_APP_ENV === "development" ? REACT_APP_DEV_URL : REACT_APP_PROD_URL;
    setLeaveId(id);
    setloader(true);
    axios
      .get(url + "/get-s3-img/" + id, {
        headers: { Authorization: `Bearer ${store.getState().auth.authToken}` },
      })
      .then((result) => {
        setviewModalVisible(true);
        setloader(false);
        setdoc(result);
      })
      .catch((err) => {
        console.log(err);
        setloader(false);
      });
  };
  const handleViewModalClose = () => {
    setviewModalVisible(false);
  };

  const DeleteRequestModal = (reqId: number) => {
    confirm({
      title: "Are you sure want to delete this request ??",
      icon: <ExclamationCircleOutlined />,

      onOk() {
        dispatch(
          leaveActions.deleteLeaveRequest({
            id: reqId,
          })
        );
        dispatch(leaveActions.getYourLeaves({}));
      },
    });
  };
  // Sample Columns data
  const columns: TableColumnsType<YourLeaveInterface> = [
    {
      title: <b style={{ color: "#F4326C" }}>Actions</b>,
      key: "action",
      render: (_, leave) => {
        return (
          leave.status.id !== 3 && (
            <Space>
              <UpdateLeaveRequestForm
                reqId={leave.id}
                start_date={leave.start_date}
                end_date={leave.end_date}
                reason={leave.reason}
                status_id={leave.status_id}
                typeId={leave.request_type.id}
                type={leave.request_type.type}
                days={leave.days}
                adjustment_date={leave.adjustment_date}
                half_leave_time={leave.half_leave_time!}
                is_emergency={leave.is_emergency}
                is_half_leave={leave.is_half_leave}
                is_alternate_shift={leave.is_alternate_shift}
              ></UpdateLeaveRequestForm>

              {leave.request_type.id === 2 &&
                (!leave.doc_url ? (
                  <>
                    <Button
                      style={{ color: "white", background: "#1890ff" }}
                      size={"middle"}
                      icon={<UploadOutlined />}
                      onClick={() => handleOpen(leave.id)}
                      title="Upload Docs"
                    ></Button>
                  </>
                ) : (
                  <>
                    <Button
                      style={{ color: "white", background: "#f6be40" }}
                      size={"middle"}
                      loading={leave.id === leaveId && loader}
                      icon={<EyeOutlined />}
                      onClick={() => handleViewModalOpem(leave.id)}
                      title="View Document"
                    ></Button>
                  </>
                ))}

              {leave.status_id === 1 && (
                <Button
                  className={"ant-btn-success"}
                  size={"middle"}
                  icon={<EditOutlined />}
                  title="Update"
                  onClick={() => {
                    dispatch(
                      leaveActions.selectedLeaveId({
                        id: leave.id,
                      })
                    );
                    dispatch(
                      leaveActions.updateLeaveModelVisible({ visible: true })
                    );
                  }}
                ></Button>
              )}
              {leave.status_id === 1 && (
                <Button
                  className={"ant-btn-danger"}
                  size={"middle"}
                  icon={<DeleteOutlined />}
                  title="Delete"
                  onClick={() => {
                    DeleteRequestModal(leave.id);
                  }}
                ></Button>
              )}
            </Space>
          )
        );
      },
    },
    {
      key: 1,
      title: <b style={{ color: "#F4326C" }}>Duration</b>,
      // dataIndex: "start",
      render: (_, leave) => {
        return (
          <>
            {moment(leave.start_date).format("DD MMM YYYY")} -{" "}
            {moment(leave.end_date).format("DD MMM YYYY")}
          </>
        );
      },
    },
    {
      key: 2,
      title: <b style={{ color: "#F4326C" }}>Type</b>,
      render: (_, leave) => (
        <>
          {leave.request_type.type}{" "}
          {leave.is_emergency === 1 && "( Emergency )"}
          {leave.is_alternate_shift === 1 && "( Alternate Shift )"}
        </>
      ),
    },
    {
      key: 3,
      title: <b style={{ color: "#F4326C" }}>Reason</b>,
      dataIndex: "reason",
      width: 200,
    },
    {
      key: 4,
      title: <b style={{ color: "#F4326C" }}>Half leave</b>,
      render: (_, leave) => (
        <>{leave.is_half_leave === 0 ? "-" : "Half leave"}</>
      ),
    },
    {
      key: 5,
      title: <b style={{ color: "#F4326C" }}>Adjustment Date</b>,
      render: (_, leave) => <>{leave.adjustment_date ?? "-"}</>,
    },
    {
      key: 6,
      title: (
        <b style={{ color: "#F4326C" }}>
          Half Leave Time /<br /> Alternate Shift Time
        </b>
      ),
      render: (_, leave) => <>{leave.half_leave_time ?? "-"}</>,
    },
    {
      key: 7,
      title: <b style={{ color: "#F4326C" }}>Days</b>,
      dataIndex: "days",
    },
    {
      key: 8,
      title: <b style={{ color: "#F4326C" }}>Status</b>,
      dataIndex: "status",
      render: (_, leave) => (
        <>
          {leave.status.state === "Pending" ? (
            <Tag
              color="#EFF5FF"
              icon={<ClockCircleFilled />}
              style={{ color: "#7B99CB" }}
            >
              {leave.status.state}
            </Tag>
          ) : leave.status.state === "Approved" ? (
            <Tag
              color="#D7FFEB"
              icon={<CheckCircleFilled />}
              style={{ color: "#38A06C" }}
            >
              {leave.status.state}
            </Tag>
          ) : (
            leave.status.state === "Denied" && (
              <Tag
                color="#FFDDE3"
                icon={<CloseCircleOutlined />}
                style={{ color: "#FA395C" }}
              >
                {leave.status.state}
              </Tag>
            )
          )}
        </>
      ),
    },
  ];

  return (
    <>
      <Table<YourLeaveInterface>
        dataSource={yourLeaves}
        columns={columns}
        pagination={{ pageSize: 5 }}
        locale={{
          emptyText: (
            <Empty
              image={emptyLeaveRequest}
              style={{ color: "black", marginTop: "50px" }}
              description=""
            ></Empty>
          ),
        }}
        className="text-center"
        rowKey={(row) => row.id}
      />
      {modalVisible ? (
        <UploadModal handleClose={handleClose} leave_id={leaveId} />
      ) : null}
      {viewModalVisible ? (
        <ViewDocModal
          handleClose={handleViewModalClose}
          image={doc}
          flag="base64-img"
        />
      ) : null}
    </>
  );
};

export default YourLeaveList;

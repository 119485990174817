import { HistoryInterface, HISTORY_LOADERS } from "../../services/api/history/types";
import { createSlice } from "@reduxjs/toolkit";
import { actions } from "./actions";
import { LMSApiError } from "../../services/api/types";

export interface HistoryState {
  histotyDetails: Record<string, HistoryInterface> | null ; 
  attendanceReport : Record<string, HistoryInterface> | null;// TODO: refactor structure when backend is done,
  loaders: Record<HISTORY_LOADERS, boolean>;
  error: LMSApiError | null;
  uploadLogLoader:boolean;
}

const initialState: HistoryState = {
  histotyDetails: null,
  attendanceReport: null,
  error: null,
  uploadLogLoader:false,
  loaders: {
    GET_HISTORY_DETAILS: true,
  },
};

const historySlice = createSlice({
  name: "history",
  initialState,
  reducers: {},
  extraReducers: {
    [actions.getHistory.type]: state => {
      state.loaders.GET_HISTORY_DETAILS = true;
    },
    [actions.getHistoryDetailsSuccess.type]: (state, action) => {
      state.loaders.GET_HISTORY_DETAILS = false;
      state.histotyDetails = action.payload.historyDetails;
    },
    [actions.getHistoryDetailsFailure.type]: (state, action) => {
      state.loaders.GET_HISTORY_DETAILS = false;
      state.error = action.payload.error;
    },

    [actions.attendanceReport.type]: state => {
      state.loaders.GET_HISTORY_DETAILS = true;
    },
    [actions.attendanceReportSuccess.type]: (state, action) => {
      state.loaders.GET_HISTORY_DETAILS = false;
      state.attendanceReport = action.payload.attendaceReport;
    },
    [actions.attendanceReportFailure.type]: (state, action) => {
      state.loaders.GET_HISTORY_DETAILS = false;
      state.error = action.payload.error;
    },
    [actions.uploadLog.type]: state => {
      state.uploadLogLoader = true;
    },
    [actions.uploadLogSuccess.type]: (state, action) => {
      state.uploadLogLoader = false;
    },
    [actions.uploadLogFailure.type]: (state, action) => {
      state.uploadLogLoader = false;
    },
  },
});
export default historySlice.reducer;
import { createAction } from "@reduxjs/toolkit";
import {
  ChangePasswordPayload,
  ForgotPasswordPayload,
  LoginPayload,
  RegisterPayload,
} from "../../services/api/auth/types";
import { LMSApiError } from "../../services/api/types";
import { AuthState } from "./reducer";

// >>>>>>> Auth ActionTypes <<<<<<<<<
export const actionTypes = {
  Login: "[Auth] Login",
  LoginSuccess: "[Auth] Login Success",
  LoginFailure: "[Auth] Login Failure",
  Logout: "[Auth] Logout",
  LogoutSuccess: "[Auth] Logout Success",
  LogoutFailure: "[Auth] Logout Failure",
  Register: "[Auth] Register",
  RegisterSuccess: "[Auth] Register Success",
  RegisterFailure: "[Auth] Register Failure",
  RenewAccessToken: "[Auth] Refresh Token",
  RenewAccessTokenSuccess: "[Auth] Refresh Token Success",
  RenewAccessTokenFailure: "[Auth] Refresh Token Failure",
  ForgotPassword: "[Auth] Forgot Password",
  ForgotPasswordSuccess: "[Auth] Forgot Password Success",
  ForgotPasswordFailure: "[Auth] Forgot Password Failure",
  ChangePassword: "[Auth] Change Password",
  ChangePasswordSuccess: "[Auth] Change Password Success",
  ChangePasswordFailure: "[Auth] Change Password Failure",
  GetUserRole: "[Auth] Get User Role",
  GetUserRoleSuccess: "[Auth] Get User Role Success",
  GetUserRoleFailure: "[Auth] Get User Role Failure",
};

// >>>>>>> Action Payload Interfaces <<<<<<<<<
interface RenewAccessTokenSuccessPayload {
  authToken: string;
}

interface RenewAccessTokenFailurePayload {
  error: LMSApiError;
}

// >>>>>>> Auth Actions <<<<<<<<<
// Created using createAction() to be type safe
export const actions = {
  login: createAction<LoginPayload>(actionTypes.Login),
  loginSuccess: createAction<AuthState>(actionTypes.LoginSuccess),
  loginFailure: createAction<AuthState>(actionTypes.LoginFailure),
  logout: createAction(actionTypes.Logout),
  logoutSuccess: createAction<AuthState>(actionTypes.LogoutSuccess),
  logoutFailure: createAction<AuthState>(actionTypes.LogoutFailure),
  register: createAction<RegisterPayload>(actionTypes.Register),
  registerSuccess: createAction<AuthState>(actionTypes.RegisterSuccess),
  registerFailure: createAction<AuthState>(actionTypes.RegisterFailure),
  renewAccessToken: createAction<string>(actionTypes.RenewAccessToken),
  renewAccessTokenSuccess: createAction<RenewAccessTokenSuccessPayload>(
    actionTypes.RenewAccessTokenSuccess
  ),
  renewAccessTokenFailure: createAction<RenewAccessTokenFailurePayload>(
    actionTypes.RenewAccessTokenFailure
  ),
  changePassword: createAction<ChangePasswordPayload>(
    actionTypes.ChangePassword
  ),
  changePasswordSuccess: createAction<AuthState>(
    actionTypes.ChangePasswordSuccess
  ),
  changePasswordFailure: createAction<AuthState>(
    actionTypes.ChangePasswordFailure
  ),
  forgotPassword: createAction<ForgotPasswordPayload>(
    actionTypes.ForgotPassword
  ),
  forgotPasswordSuccess: createAction<AuthState>(
    actionTypes.ForgotPasswordSuccess
  ),
  forgotPasswordFailure: createAction<AuthState>(
    actionTypes.ForgotPasswordFailure
  ),
  
};

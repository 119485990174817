// New store with typescript:
import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import { createLogger } from 'redux-logger';
import createSagaMiddleware from "redux-saga";
import storage from "redux-persist/lib/storage";
import services from "../services";
import { rootReducer } from "./rootReducer";
import { rootSaga } from "./rootSaga";

const sagaMiddleware = createSagaMiddleware({
  context: {
    api: services.api,
    log: services.log,
  },
});

const logger = createLogger({
  collapsed: true,
  duration: true,
});

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth"],
};

const persistReducerConfig = persistReducer(persistConfig, rootReducer);

const middlewares: any = [sagaMiddleware];

if (process.env.NODE_ENV === `development`) {
  middlewares.push(logger);
}

// configureStore: automatically configure the Redux DevTools extension
// so that you can inspect the store while developing.
const store = configureStore({
  reducer: persistReducerConfig,
  middleware: middlewares,
});

sagaMiddleware.run(rootSaga);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export const persistor = persistStore(store);

export default store;
